import React from 'react'
import { Select } from '@sainsburys-tech/fable'

export const SelectExample = () => {
  return (
    <Select
      label="Label"
      onChange={function noRefCheck() {}}
      options={[
        {
          label: 'Option 1',
          value: '1',
        },
        {
          disabled: true,
          label: 'Option 2',
          value: '2',
        },
        {
          label: 'Option 3',
          value: '3',
        },
        {
          label: 'Option 4',
          value: '4',
        },
      ]}
    />
  )
}
