import React from 'react'
import { List, ListItem } from '@sainsburys-tech/fable'
import { Star, Locked, ListView } from '@sainsburys-tech/icons'

const ListExample = () => {
  return (
    <List>
      <ListItem>ListItem 1 content</ListItem>
      <ListItem isSelected>ListItem 2 content</ListItem>
      <ListItem leadingIcon={<Star />}>ListItem 3 content</ListItem>
      <ListItem isSelected leadingIcon={<ListView />} trailingIcon={<Locked />}>
        ListItem 4 content
      </ListItem>
    </List>
  )
}

export const InlineListExample = () => {
  return (
    <List isInline>
      <ListItem isInline>ListItem 1 content</ListItem>
      <ListItem isInline isSelected>
        ListItem 2 content
      </ListItem>
      <ListItem isInline leadingIcon={<Star />}>
        ListItem 3 content
      </ListItem>
      <ListItem
        isInline
        isSelected
        leadingIcon={<ListView />}
        trailingIcon={<Locked />}
      >
        ListItem 4 content
      </ListItem>
    </List>
  )
}
export default ListExample
