import React from 'react'
import { Carousel } from '@sainsburys-tech/fable'

export const CarouselExample = () => {
  return (
    <Carousel
      heading={{ text: 'Carousel Heading', displayType: 'display-3' }}
      supportingText="Supporting Text"
      moreLink="www.example.com"
    >
      {Array.from({ length: 22 }, (_, i) => i + 1).map(item => (
        <div
          key={item}
          href="www.example.com"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,
            width: 250,
            backgroundColor: '#F2F2F2',
          }}
        >
          <div
            style={{
              color: '#737373',
            }}
          >
            Tile Content
          </div>
          <div
            style={{
              color: '#737373',
            }}
          >
            Swap Instance
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export const CarouselExampleLink = () => {
  return (
    <Carousel
      heading={{ text: 'Carousel Heading', displayType: 'display-3' }}
      headerLink={{
        href: 'www.example.com',
        text: 'See More',
      }}
      moreLink="www.example.com"
    >
      {Array.from({ length: 22 }, (_, i) => i + 1).map(item => (
        <div
          key={item}
          href="www.example.com"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,
            width: 250,
            backgroundColor: '#F2F2F2',
          }}
        >
          <div
            style={{
              color: '#737373',
            }}
          >
            Tile Content
          </div>
          <div
            style={{
              color: '#737373',
            }}
          >
            Swap Instance
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export const CarouselExampleSmallHeader = () => {
  return (
    <Carousel
      heading={{ text: 'Carousel Heading', displayType: 'display-3' }}
      moreLink="www.example.com"
    >
      {Array.from({ length: 22 }, (_, i) => i + 1).map(item => (
        <div
          key={item}
          href="www.example.com"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,
            width: 250,
            backgroundColor: '#F2F2F2',
          }}
        >
          <div
            style={{
              color: '#737373',
            }}
          >
            Tile Content
          </div>
          <div
            style={{
              color: '#737373',
            }}
          >
            Swap Instance
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export const CarouselExampleBigHeader = () => {
  return (
    <Carousel
      heading={{ text: 'Carousel Heading', displayType: 'display-5' }}
      moreLink="www.example.com"
    >
      {Array.from({ length: 22 }, (_, i) => i + 1).map(item => (
        <div
          key={item}
          href="www.example.com"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 250,
            width: 250,
            backgroundColor: '#F2F2F2',
          }}
        >
          <div
            style={{
              color: '#737373',
            }}
          >
            Tile Content
          </div>
          <div
            style={{
              color: '#737373',
            }}
          >
            Swap Instance
          </div>
        </div>
      ))}
    </Carousel>
  )
}
