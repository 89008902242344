import React from 'react'
import { Tabs, Tab } from '@sainsburys-tech/fable'

export const PrimaryTabsExample = () => {
  return (
    <Tabs>
      <Tab label="Tab 1" value="tab-1">
        <p>Tab 1 content</p>
      </Tab>
      <Tab label="Tab 2" value="tab-2">
        <p>Tab 2 content</p>
      </Tab>
      <Tab label="Tab 3" value="tab-3">
        <p>Tab 3 content</p>
      </Tab>
    </Tabs>
  )
}

export const SecondaryTabsExample = () => {
  return (
    <Tabs variant="secondary">
      <Tab label="Tab 1" value="tab-1">
        <p>Tab 1 content</p>
      </Tab>
      <Tab label="Tab 2" value="tab-2">
        <p>Tab 2 content</p>
      </Tab>
      <Tab label="Tab 3" value="tab-3">
        <p>Tab 3 content</p>
      </Tab>
    </Tabs>
  )
}
