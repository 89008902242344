import React from 'react'
import {
  ProductCard,
  BadgeGroup,
  Badge,
  Rating,
  Button,
  IconButton,
  Price,
} from '@sainsburys-tech/fable'
import { Favourites } from '@sainsburys-tech/icons'

import PlaceholderImage from '@images/components/product-card/product_card_product_image.png'

export const ProductCardVerticalExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <div>
        <ProductCard
          title="Product Card Title"
          hasPadding
          image={
            <div className="ds-max-w-full">
              <img
                src={PlaceholderImage}
                alt="Product Card placeholder"
                className="ds-w-full ds-max-w-full ds-object-cover ds-object-center"
              />
            </div>
          }
          rating={{
            count: 120,
            value: 4.5,
          }}
          badges={[
            {
              type: 'clearance',
            },
            {
              type: 'new',
            },
          ]}
          price={{
            price: '£0.00',
            type: 'copy',
            copyText: 'copy text',
            strikePrice: '£0.00',
          }}
          ctaButton={{
            label: 'Button',
            href: 'http://www.example.com',
            onClick: () => {},
          }}
          secondaryCtaButton={{
            label: 'Add to favourites',
            href: 'http://www.example.com',
            onClick: () => {},
          }}
        />
      </div>
    </div>
  )
}

export const ProductCardHorizontalExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <div>
        <ProductCard
          title="Product Card Title"
          orientation="horizontal"
          hasPadding
          image={
            <div className="ds-max-w-full">
              <img
                src={PlaceholderImage}
                alt="Product Card placeholder"
                className="ds-w-full ds-max-w-full ds-object-cover ds-object-center"
              />
            </div>
          }
          rating={{
            count: 120,
            value: 4.5,
          }}
          badges={[
            {
              type: 'clearance',
            },
            {
              type: 'new',
            },
          ]}
          price={{
            price: '£0.00',
            type: 'copy',
            copyText: 'copy text',
            strikePrice: '£0.00',
          }}
          ctaButton={{
            label: 'Button',
            href: 'http://www.example.com',
            onClick: () => {},
          }}
          secondaryCtaButton={{
            label: 'Add to favourites',
            href: 'http://www.example.com',
            onClick: () => {},
          }}
        />
      </div>
    </div>
  )
}

export const BadgeExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <BadgeGroup>
        <Badge type="clearance" isCompact />
        <Badge type="new" isCompact />
        <Badge type="grey" label="badge" variant="secondary" isCompact />
        <Badge type="darkblue" label="badge" variant="secondary" isCompact />
        <Badge type="green" label="badge" variant="secondary" isCompact />
      </BadgeGroup>
    </div>
  )
}

export const RatingExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <Rating rating={4.527777777777778} ratingCount={26} />
    </div>
  )
}

export const PriceExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-evenly">
      <div>
        <Price
          type="copy"
          price="£0.00"
          copyText="Copy here"
          strikePrice="£0.00"
        />
      </div>
      <div>
        <Price
          isOffer
          price="0.00"
          pricePerUnit={{
            price: '£0.00',
            unit: 'unit',
          }}
          strikePrice="£0.00"
          type="default"
        />
      </div>
      <div>
        <Price
          nectarPrice="£0.00"
          price="£0.00"
          pricePerUnit={{
            price: '£0.00',
            unit: 'unit',
          }}
          type="nectar-price"
        />
      </div>
    </div>
  )
}

export const ButtonExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <Button onClick={function noRefCheck() {}} variant="primary">
        Add
      </Button>
    </div>
  )
}

export const ProductCardIconButtonExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <IconButton
        hasHiddenText
        as="a"
        icon={<Favourites />}
        label="Add to favourites"
        onClick={function noRefCheck() {}}
        variant="secondary"
      />
    </div>
  )
}
