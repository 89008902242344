import React from 'react'
import { ColourSwatch } from '@sainsburys-tech/fable'

export const ColourSwatchLinkExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <ColourSwatch
        hasDisabledAtEnd
        onChange={function noRefCheck() {}}
        swatches={[
          {
            colour: '#0000FF',
            href: '#',
            name: 'Blue',
          },
          {
            colour: '#FF0000',
            href: '#',
            name: 'Red',
          },
          {
            colour: '#FFFF00',
            href: '#',
            name: 'Yellow',
          },
          {
            colour: '#800080',
            href: '#',
            name: 'Purple',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
          },
        ]}
        truncationAmount={5}
        truncationLink="#"
        type="link"
      />
    </div>
  )
}

export const ColourSwatchRadioExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <ColourSwatch
        onChange={function noRefCheck() {}}
        swatches={[
          {
            colour: '#0000FF',
            href: '#',
            name: 'Blue',
            value: 'blue',
          },
          {
            colour: '#FF0000',
            href: '#',
            name: 'Red',
            value: 'red',
          },
          {
            colour: '#FFFF00',
            href: '#',
            name: 'Yellow',
            value: 'yellow',
          },
          {
            colour: '#800080',
            href: '#',
            name: 'Purple',
            value: 'purple',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Green',
            value: 'green',
          },
          {
            colour: '#B87333',
            material: 'metal',
            name: 'Copper',
            value: 'copper',
          },
          {
            colour: '#773F1A',
            material: 'wood',
            name: 'Walnut',
            value: 'walnut',
          },
          {
            colour: '#676767',
            material: 'stone',
            name: 'Granite',
            value: 'granite',
          },
        ]}
        type="radio"
      />
    </div>
  )
}

export const ColourSwatchColourExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <ColourSwatch
        onChange={function noRefCheck() {}}
        swatches={[
          {
            colour: '#FC6363',
            href: '#',
            name: 'Pink',
            value: 'pink',
          },
          {
            colour: '#C23838',
            href: '#',
            name: 'Red',
            value: 'red',
          },
          {
            colour: '#E9D090',
            href: '#',
            name: 'Sand',
            value: 'sand',
          },
          {
            colour: '#E5E84E',
            href: '#',
            name: 'Yellow',
            value: 'yellow',
          },
          {
            colour: '#00FF00',
            href: '#',
            name: 'Lime',
            value: 'lime',
          },
          {
            colour: '#7FE142',
            name: 'Green',
            value: 'green',
          },
          {
            colour: '#75BBFC',
            name: 'Turquoise',
            value: 'turquoise',
          },
          {
            colour: '#0C6BF9',
            name: 'Blue',
            value: 'blue',
          },
        ]}
        type="radio"
      />
    </div>
  )
}

export const ColourSwatchMetalExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <ColourSwatch
        onChange={function noRefCheck() {}}
        swatches={[
          {
            colour: '#FC6363',
            material: 'metal',
            href: '#',
            name: 'Metallic Pink',
            value: 'pink',
          },
          {
            colour: '#C23838',
            material: 'metal',
            href: '#',
            name: 'Metallic Red',
            value: 'red',
          },
          {
            colour: '#E9D090',
            material: 'metal',
            href: '#',
            name: 'Metallic Sand',
            value: 'sand',
          },
          {
            colour: '#E5E84E',
            material: 'metal',
            href: '#',
            name: 'Metallic Yellow',
            value: 'yellow',
          },
          {
            colour: '#00FF00',
            material: 'metal',
            href: '#',
            name: 'Metallic Lime',
            value: 'lime',
          },
          {
            colour: '#7FE142',
            material: 'metal',
            name: 'Metallic Green',
            value: 'green',
          },
          {
            colour: '#75BBFC',
            material: 'metal',
            name: 'Metallic Turquoise',
            value: 'turquoise',
          },
          {
            colour: '#0C6BF9',
            material: 'metal',
            name: 'Metallic Blue',
            value: 'blue',
          },
        ]}
        type="radio"
      />
    </div>
  )
}

export const ColourSwatchWoodExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center">
      <ColourSwatch
        onChange={function noRefCheck() {}}
        swatches={[
          {
            colour: '#FC6363',
            material: 'wood',
            href: '#',
            name: 'Wooden Pink',
            value: 'pink',
          },
          {
            colour: '#C23838',
            material: 'wood',
            href: '#',
            name: 'Wooden Red',
            value: 'red',
          },
          {
            colour: '#E9D090',
            material: 'wood',
            href: '#',
            name: 'Wooden Sand',
            value: 'sand',
          },
          {
            colour: '#E5E84E',
            material: 'wood',
            href: '#',
            name: 'Wooden Yellow',
            value: 'yellow',
          },
          {
            colour: '#00FF00',
            material: 'wood',
            href: '#',
            name: 'Wooden Lime',
            value: 'lime',
          },
          {
            colour: '#7FE142',
            material: 'wood',
            name: 'Wooden Green',
            value: 'green',
          },
          {
            colour: '#75BBFC',
            material: 'wood',
            name: 'Wooden Turquoise',
            value: 'turquoise',
          },
          {
            colour: '#0C6BF9',
            material: 'wood',
            name: 'Wooden Blue',
            value: 'blue',
          },
        ]}
        type="radio"
      />
    </div>
  )
}
