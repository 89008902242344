import EnergyLabelAndPrice1 from '@images/components/energy-label/EnergyLabelAndPrice1_x2.png';
import EnergyLabelAndPrice2 from '@images/components/energy-label/EnergyLabelAndPrice2_x2.png';
import EnergyLabelLinks1 from '@images/components/energy-label/EnergyLabelLinks1_x2.png';
import EnergyLabelLinks2 from '@images/components/energy-label/EnergyLabelLinks2_x2.png';
import * as React from 'react';
export default {
  EnergyLabelAndPrice1,
  EnergyLabelAndPrice2,
  EnergyLabelLinks1,
  EnergyLabelLinks2,
  React
};