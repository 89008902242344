import React from 'react'
import {
  TableContainer,
  TableHeader,
  TableRow,
  TableHeaderRow,
  TableCell,
} from '@jsluna/table'

const HeadingDisplayTable = () => (
  <TableContainer>
    <TableHeader>
      <TableHeaderRow>
        <TableCell>Element</TableCell>
        <TableCell>Suggested default display size</TableCell>
        <TableCell>Alternative small display size</TableCell>
      </TableHeaderRow>
    </TableHeader>
    <TableRow>
      <TableCell>{'<h1>'}</TableCell>
      <TableCell>Display 5</TableCell>
      <TableCell>Display 4</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>{'<h2>'}</TableCell>
      <TableCell>Display 4</TableCell>
      <TableCell>Display 3</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>{'<h3>'}</TableCell>
      <TableCell>Display 3</TableCell>
      <TableCell>Display 2</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>{'<h4>'}</TableCell>
      <TableCell>Display 2</TableCell>
      <TableCell>Display 1</TableCell>
    </TableRow>
    <TableRow>
      <TableCell>{'<h5>'}</TableCell>
      <TableCell>Display 1</TableCell>
      <TableCell></TableCell>
    </TableRow>
    <TableRow>
      <TableCell>{'<body>'}</TableCell>
      <TableCell>Body 1</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableContainer>
)

export default HeadingDisplayTable
