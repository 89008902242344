import React from 'react'
import Card from '@components/card'

const cardStyles = {
  positive: 'var(--ds-color-semantic-success-base)',
  negative: 'var(--ds-color-semantic-error-base)',
  neutral: 'var(--ds-color-semantic-info-base)',
}

const PolarityCard = ({
  center = false,
  children,
  hasCodeSnippet = false,
  title,
  type,
  ...rest
}) => {
  const setTitle = title ? title : type === 'positive' ? 'Do' : "Don't"
  return (
    <Card
      {...rest}
      hasCodeSnippet={hasCodeSnippet}
      className={`c-card--${type} ln-u-display-flex ln-u-flex-direction-col`}
    >
      <strong
        className={`ln-u-soft-bottom-sm ${hasCodeSnippet && `ln-u-soft-sides`}`}
        style={{
          color: cardStyles[type] || 'var(--ds-color-semantic-info-base)',
        }}
      >
        {setTitle}
      </strong>
      <div
        className={
          hasCodeSnippet &&
          `ln-u-display-flex ln-u-flex-direction-col ln-u-flex-grow`
        }
        style={{
          justifyContent: center && `center`,
        }}
      >
        {children}
      </div>
    </Card>
  )
}

export const Do = (props, { title }) => (
  <PolarityCard {...props} type="positive" />
)

export const Dont = (props, { title }) => (
  <PolarityCard {...props} type="negative" />
)

export const Box = props => <PolarityCard {...props} type="neutral" />

export const Don2t = ({
  title = "Don't",
  center = false,
  children,
  hasCodeSnippet = false,
  ...rest
}) => (
  <Card
    {...rest}
    hasCodeSnippet={hasCodeSnippet}
    className="c-card--negative ln-u-display-flex ln-u-flex-direction-col"
  >
    <strong
      className={`ln-u-soft-bottom-sm ${hasCodeSnippet && `ln-u-soft-sides`}`}
      style={{ color: 'var(--ds-color-semantic-error-base)' }}
    >
      {title}
    </strong>
    <div
      className={
        hasCodeSnippet &&
        `ln-u-display-flex ln-u-flex-direction-col ln-u-flex-grow`
      }
      style={{
        justifyContent: center && `center`,
      }}
    >
      {children}
    </div>
  </Card>
)

export const Guideline = props => (
  <div className="c-guideline--container" {...props} />
)
