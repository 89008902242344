import TextImagesDONT from '@images/a11y/1.1-Text-Images-DONT.png';
import LinkTextDo from '@images/a11y/Native-link-text-1.4-Do.png';
import LinkTextDont from '@images/a11y/Native-link-text-1.4-Dont.png';
import DecorativeImageDo from '@images/a11y/Native-decorative-image-1.5-Do.png';
import DecorativeImageDont from '@images/a11y/Native-decorative-image-1.5-Dont.png';
import ColourAloneDO2 from '@images/a11y/2.4-Colour-Alone-DO_2.png';
import ColourAloneDONT2 from '@images/a11y/2.4-Colour-Alone-DONT_2.png';
import TextAlternativesDO from '@images/a11y/1.2-Text-alternatives-DO.png';
import TextAlternativesDONT from '@images/a11y/1.2-Text-alternatives-DONT.png';
import TextAlternativesDO2 from '@images/a11y/1.2-Text-alternatives-DO_2.png';
import TextAlternativesDONT2 from '@images/a11y/1.2-Text-alternatives-DONT_2.png';
import TextAlternativesDO4 from '@images/a11y/1.2-Text-alternatives-DO_4.png';
import TextAlternativesDONT4 from '@images/a11y/1.2-Text-alternatives-DONT_4.png';
import TextAlternativesDO5 from '@images/a11y/Text-alternatives-DO_5.png';
import TextAlternativesDO6 from '@images/a11y/Text-alternatives-DO_6.png';
import { Accordion, AccordionItem } from '@jsluna/accordion';
import { default as CodeSnippet } from '@components/accessibility-sections/CodeSnippet';
import * as React from 'react';
export default {
  TextImagesDONT,
  LinkTextDo,
  LinkTextDont,
  DecorativeImageDo,
  DecorativeImageDont,
  ColourAloneDO2,
  ColourAloneDONT2,
  TextAlternativesDO,
  TextAlternativesDONT,
  TextAlternativesDO2,
  TextAlternativesDONT2,
  TextAlternativesDO4,
  TextAlternativesDONT4,
  TextAlternativesDO5,
  TextAlternativesDO6,
  Accordion,
  AccordionItem,
  CodeSnippet,
  React
};