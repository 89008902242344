import React from 'react'

import { Button as LnButton } from '@jsluna/button'

const Button = ({ element, href, children, ...rest }) => {
  if (element === 'a') {
    if (href.startsWith('https') || href.startsWith('http')) {
      return (
        <LnButton
          element={element}
          href={href}
          {...rest}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {children}
        </LnButton>
      )
    }

    return (
      <LnButton
        element={element}
        href={href.endsWith('/') ? href : `${href}/`}
        {...rest}
      >
        {children}
      </LnButton>
    )
  }

  return (
    <LnButton element={element} {...rest}>
      {children}
    </LnButton>
  )
}

export default Button
