import React from 'react'
import { Switch } from '@sainsburys-tech/fable'

const SwitchExample = () => {
  return <Switch label="Label" onChange={function noRefCheck() {}} />
}
export const OutlinedSwitchExample = () => {
  return (
    <Switch isOutlined label="Outlined" onChange={function noRefCheck() {}} />
  )
}

export const ErrorSwitchExample = () => {
  return (
    <Switch status="error" label="Error" onChange={function noRefCheck() {}} />
  )
}

export const DisabledSwitchExample = () => {
  return (
    <Switch isDisabled label="Disabled" onChange={function noRefCheck() {}} />
  )
}

export const StandaloneSwitchExample = () => {
  return (
    <Switch
      isStandalone
      label="Standalone"
      onChange={function noRefCheck() {}}
    />
  )
}

export const WithTextSwitchExample = () => {
  return <Switch label="With Text" onChange={function noRefCheck() {}} />
}

export default SwitchExample
