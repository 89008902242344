import React from "react";
import { Box } from "@sainsburys-tech/fable";

const BoxExample = () => {
    return (
        <Box
  as="div"
  borderRadius="sm"
  hasBorder
  padding={{
    lg: 'lg',
    md: 'md',
    sm: 'sm'
  }}
>
  <h2>
    Responsive Padding Box
  </h2>
  <p>
    This box has responsive padding
  </p>
</Box>
    )
}

export default BoxExample