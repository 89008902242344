import React from 'react'
import { Input } from '@sainsburys-tech/fable'
import { Edit, Cancel } from '@sainsburys-tech/icons'

export const StandardInputExample = () => {
  return (
    <Input
      label="Standard Input"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      type="text"
    />
  )
}

export const RequiredInputExample = () => {
  return (
    <Input
      isRequired
      label="Required Input"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      type="text"
    />
  )
}

export const SupportingTextInputExample = () => {
  return (
    <Input
      label="Input"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      supportingText="With Supporting text"
      type="text"
    />
  )
}

export const PlaceholderTextInputExample = () => {
  return (
    <Input
      label="Label"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      placeholder="Placeholder"
      type="text"
    />
  )
}

export const ErrorMessageTextInputExample = () => {
  return (
    <Input
      label="With error message"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      status={{
        message: 'Error message',
        type: 'error',
      }}
      type="text"
    />
  )
}

export const IconInputExample = () => {
  return (
    <Input
      label="With Buttons"
      leadingIcon={<Edit />}
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      trailingButton={{
        icon: <Cancel />,
        label: 'Cancel icon',
        onClick: function noRefCheck() {},
      }}
      type="text"
    />
  )
}

export const ButtonInputExample = () => {
  return (
    <Input
      button="Button"
      label="Label"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      type="text"
    />
  )
}
