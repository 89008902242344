import React from "react";
import { Header } from "@sainsburys-tech/fable";

const HeaderExample = () => {
    return (
        <Header
  appName="App Name"
  homepageLink="#"
  logo="sainsburys"
  navLinks={[
    {
      dropDownLinks: [
        {
          href: '#',
          label: 'One.One'
        },
        {
          href: '#',
          label: 'One.Two'
        }
      ],
      label: 'One'
    },
    {
      dropDownLinks: [
        {
          href: '#',
          label: 'Two.One'
        },
        {
          href: '#',
          label: 'Two.Two'
        }
      ],
      label: 'Two'
    },
    {
      href: '#',
      label: 'Three'
    },
    {
      href: '#',
      label: 'Four'
    },
    {
      dropDownLinks: [
        {
          href: '#',
          label: 'Five.One'
        },
        {
          href: '#',
          label: 'Five.Two'
        }
      ],
      label: 'Five'
    }
  ]}
  skipLink="#"
/>
    )
}

export default HeaderExample