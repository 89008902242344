import React from 'react'
import { DatePicker } from '@sainsburys-tech/fable'

const DatePickerExample = () => {
  return (
    <DatePicker
      label="Date"
      onChange={function noRefCheck() {}}
      onClear={function noRefCheck() {}}
      onSelectDate={function noRefCheck() {}}
    />
  )
}

export default DatePickerExample
