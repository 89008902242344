import React from 'react'
import { formatFontName } from '@utils/formatTokenTable'


const conversions = {
  100: 'Thin',
  200: 'Extra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Black',
}

const TypographyToken = ({ platform, token, tokenItem, typographyTokens }) => {
  const tokenValue = typographyTokens?.stack?.[token]?.[tokenItem]

  if (tokenValue && tokenItem !== 'font-family') {
    return (
      <div>
        {tokenItem === 'line-height' && tokenValue.toString().length > 4
          ? tokenValue.toFixed(3)
          : tokenValue}
      </div>
    )
  } else if (tokenValue && tokenItem === 'font-family') {
    const { 'font-family': fontFamily, 'font-weight': fontWeight } =
      typographyTokens?.stack?.[token]

    const fontName = formatFontName({
      fontFamily,
      fontWeight: conversions[fontWeight],
    })

    const nativeLabel1FontWeight = token === 'label-1' ? 700 : 400

    if (
      platform === 'native' &&
      !token.includes('display') &&
      token !== 'label-2'
    ) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              fontFamily: `${typographyTokens?.family?.ios}`,
              fontWeight: `${nativeLabel1FontWeight}`,
            }}
          >{`${typographyTokens?.family?.ios} ${conversions[nativeLabel1FontWeight]} (iOS)`}</span>
          <span
            style={{
              fontFamily: `${typographyTokens?.family?.android}`,
              fontWeight: `${nativeLabel1FontWeight}`,
            }}
          >{`${typographyTokens?.family?.android} ${conversions[nativeLabel1FontWeight]} (Android)`}</span>
        </div>
      )
    }
    return <div>{fontName}</div>
  } else {
    return <div>No value found</div>
  }
}

export default TypographyToken
