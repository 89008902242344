import React from 'react'
import MarkdownLink from '@components/markdown-link'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display1 } from '@jsluna/typography'
import Card from '@components/card'
import { Section } from '@jsluna/section'

import Barlow from '@jsluna/style/dist/fonts/Barlow'
import Inhabit from '@jsluna/style/dist/fonts/Inhabit'
import MaryAnn from '@jsluna/style/dist/fonts/MaryAnn'
import ItalianPlate from '@jsluna/style/dist/fonts/ItalianPlate'
import TuFont from '@jsluna/style/dist/fonts/Tu'

const hasBorder = (border, removePadding) => {
  return removePadding ? !removePadding : border
}

export const GridListItem = ({
  children,
  title,
  titleElement,
  linkText,
  linkTo,
  border,
  removePadding,
  filled,
  fontTheme,
  icon,
}) => {
  return (
    <Card
      rounded={true}
      border={hasBorder(border, removePadding)}
      hard={removePadding}
      filled={filled}
      fontTheme={fontTheme}
      icon={icon}
    >
      {title && (
        <>
          {fontTheme === 'sainsburys' && <MaryAnn />}
          {fontTheme === 'argos' && <Barlow />}
          {fontTheme === 'nectar' && <ItalianPlate />}
          {fontTheme === 'habitat' && <Inhabit />}
          {fontTheme === 'tu' && <TuFont />}
          <Display1
            element={titleElement}
            fontTheme={fontTheme}
            className="c-card--title"
          >
            {title}
          </Display1>
        </>
      )}
      {children}
      {linkTo && linkText && (
        <div className="c--cta-link">
          <MarkdownLink
            href={linkTo.endsWith('/') ? linkTo : `${linkTo}/`}
            className="ln-c-link ln-c-link--bare ln-c-link--cta"
          >
            {linkText}
          </MarkdownLink>
        </div>
      )}
    </Card>
  )
}

const GridListWrapper = ({
  children,
  columns,
  title,
  titleElement,
  fontTheme,
}) => {
  return (
    <Section>
      {title && (
        <>
          {fontTheme === 'sainsburys' && <MaryAnn />}
          {fontTheme === 'argos' && <Barlow />}
          {fontTheme === 'nectar' && <ItalianPlate />}
          {fontTheme === 'habitat' && <Inhabit />}
          {fontTheme === 'tu' && <TuFont />}
          <Display1
            element={titleElement}
            fontTheme={fontTheme}
            className="c-card--title"
          >
            {title}
          </Display1>
        </>
      )}
      <GridWrapper equalHeight={true} matrix={true}>
        {React.Children.map(children, child => {
          return (
            <GridItem
              key={child.props.title}
              size={{ sm: '1', md: `1/${columns}` }}
            >
              {child.props.mdxType !== 'GridListItem' ? (
                <GridListItem
                  {...child.props}
                  removePadding={
                    child.props.mdxType === 'ColourLookup' ||
                    child.props.mdxType === 'ColourBlock'
                      ? true
                      : child.props.removePadding
                  }
                >
                  {child}
                </GridListItem>
              ) : (
                child
              )}
            </GridItem>
          )
        })}
      </GridWrapper>
    </Section>
  )
}

export default GridListWrapper
