import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/partial.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Display2 = makeShortcode("Display2");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>
      <div>
        <Display2 mdxType="Display2">This is a responsive layout example</Display2>
        <p>A single design that reflows across different displays</p>
      </div>
    </div>
  </Do>
  <Dont mdxType="Dont">
    <div style={{
          maxHeight: '200px',
          maxWidth: '100%',
          overflow: 'scroll'
        }}>
      <div style={{
            width: '150%',
            height: '300px'
          }}>
        <Display2 mdxType="Display2">This is not a responsive layout example</Display2>
        <p>Content does not reflow and adjust to different displays</p>
      </div>
    </div>
  </Dont>
    </Guideline>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      