import React from 'react'
import { Alert } from '@sainsburys-tech/fable'

export const PrimaryAlertExampleBundle = () => {
  return (
    <>
      <Alert isOpen message="Primary Info alert message" status="info" />
      <Alert isOpen message="Primary Success alert message" status="success" />
      <Alert isOpen message="Primary Error alert message" status="error" />
      <Alert isOpen message="Primary Warning alert message" status="warning" />
    </>
  )
}

export const SecondaryAlertExampleBundle = () => {
  return (
    <>
      <Alert
        isOpen
        message="Secondary Info alert message"
        status="info"
        variant="secondary"
      />
      <Alert
        isOpen
        message="Secondary Success alert message"
        status="success"
        variant="secondary"
      />
      <Alert
        isOpen
        message="Secondary Error alert message"
        status="error"
        variant="secondary"
      />
      <Alert
        isOpen
        message="Secondary Warning alert message"
        status="warning"
        variant="secondary"
      />
    </>
  )
}

export const StandardAlertExample = () => {
  return (
    <>
      <Alert
        isOpen
        message="Standard info alert message"
        status="info"
        variant="secondary"
      />
    </>
  )
}

export const StandardAlertDismissableExample = () => {
  return (
    <>
      <Alert
        isOpen
        message="Standard info alert message"
        status="info"
        variant="secondary"
        onDismiss={function noRefCheck() {}}
      />
    </>
  )
}

export const InformationAlertExample = () => {
  return (
    <>
      <Alert
        isOpen
        message="1 new task’s been assigned to you since you last logged in."
        status="info"
        variant="secondary"
      />
    </>
  )
}

export const ErrorAlertExample = () => {
  return (
    <>
      <Alert
        isOpen
        message="You haven’t added your address. Please provide your address to continue."
        status="error"
        variant="secondary"
      />
    </>
  )
}
export const WarningAlertExample = () => {
  return (
    <>
      <Alert
        isOpen
        message="You haven’t added a phone number to your account. Adding a number means we can get in touch if we need to."
        status="warning"
        variant="secondary"
      />
    </>
  )
}
export const SuccessAlertExample = () => {
  return (
    <>
      <Alert
        isOpen
        message="We’ve added ‘Heinz Baked Beans’ to your trolley."
        status="success"
        variant="secondary"
      />
    </>
  )
}
