import React, { ReactNode } from 'react'

import { Link as LnLink } from '@jsluna/link'

import styled from 'styled-components'

interface OutlineExample {
  children: ReactNode
}

const OutlineExampleDontStyled = styled(LnLink)`
  &:focus,
  :hover {
    outline: none !important;
    box-shadow: none;
  }
`

const OutlineExampleDoStyled = styled(LnLink)`
  &:focus,
  :hover {
    outline: 5px auto !important;
  }
`

export const OutlineExampleDo = ({ children }: OutlineExample) => (
  <OutlineExampleDoStyled href="#">{children}</OutlineExampleDoStyled>
)

export const OutlineExampleDont = ({ children }: OutlineExample) => (
  <OutlineExampleDontStyled href="#">{children}</OutlineExampleDontStyled>
)
