import React from 'react'
import { Accordion, AccordionItem, Link } from '@sainsburys-tech/fable'

export const AccordionExample = () => {
  return (
    <div>
      <Accordion hasMultipleOpen>
        <AccordionItem label={'Accordion Item 1'}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente quod
          dolorum possimus alias provident. Ducimus ab, obcaecati deserunt
          laboriosam rem expedita illo nisi iste nobis, perferendis harum vel
          voluptas mollitia!
        </AccordionItem>
        <AccordionItem label={'Accordion Item 2'}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
          rerum saepe officiis architecto enim amet eius ab, excepturi velit
          libero. Quae enim perspiciatis voluptatem ut odio voluptatum adipisci,
          voluptatibus consequatur.
        </AccordionItem>
        <AccordionItem label={'Accordion Item 3'}>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo laborum
          nemo itaque voluptates perspiciatis quasi neque deserunt explicabo
          accusamus hic? Ullam libero eligendi, reiciendis neque architecto
          voluptates explicabo possimus quo.
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export const AccordionWithFooterExample = () => {
  return (
    <div>
      <Accordion>
        <AccordionItem
          footer={
            <Link fontStyle="body1" href="#">
              Link
            </Link>
          }
          label="Accordion Item 1"
          size="md"
        >
          Insert your accordion content in here.
        </AccordionItem>
        <AccordionItem
          footer={
            <Link fontStyle="body1" href="#">
              Link
            </Link>
          }
          label="Accordion Item 2"
          size="md"
        >
          Insert your accordion content in here.
        </AccordionItem>
        <AccordionItem
          footer={
            <Link fontStyle="body1" href="#">
              Link
            </Link>
          }
          label="Accordion Item 3"
          size="md"
        >
          Insert your accordion content in here.
        </AccordionItem>
        <AccordionItem
          footer={
            <Link fontStyle="body1" href="#">
              Link
            </Link>
          }
          label="Accordion Item 4"
          size="md"
        >
          Insert your accordion content in here.
        </AccordionItem>
      </Accordion>
    </div>
  )
}
