import React from 'react'
import { PasswordInput } from '@sainsburys-tech/fable'

const PasswordInputExample = () => {
  return (
    <div>
      <PasswordInput
        onBlur={function noRefCheck() {}}
        onChange={function noRefCheck() {}}
        onFocus={function noRefCheck() {}}
        onStatusChange={function noRefCheck() {}}
        rules={{
          contains: {
            minNumbers: 4,
            minUppercase: 2,
            specialCharacters: 3,
          },
          length: {
            minCharacters: 8,
          },
        }}
      />
    </div>
  )
}

export const PasswordInputWIthVisibilityToggle = () => {
  return (
    <div>
      <PasswordInput
        onBlur={function noRefCheck() {}}
        onChange={function noRefCheck() {}}
        onFocus={function noRefCheck() {}}
        onStatusChange={function noRefCheck() {}}
        hasVisibilityToggle
      />
    </div>
  )
}

export default PasswordInputExample
