import React from 'react'
import { CheckboxGroup } from '@sainsburys-tech/fable'

export const CheckboxGroupVertical = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CheckboxGroup
        label="Label"
        supportingText="Supporting text"
        name="example"
        options={[
          {
            label: 'First option',
            value: 'first',
          },
          {
            label: 'Second option',
            value: 'second',
          },
          {
            label: 'Third option',
            value: 'third',
          },
        ]}
      />
    </div>
  )
}

export const CheckboxGroupHorizontal = () => {
  return (
    <CheckboxGroup
      label="Label"
      supportingText="Supporting text"
      isInline
      options={[
        {
          label: 'First option',
          value: 'first',
        },
        {
          label: 'Second option',
          value: 'second',
        },
        {
          label: 'Third option',
          value: 'third',
        },
      ]}
    />
  )
}
