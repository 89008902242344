import React from 'react'
import * as LnTable from '@jsluna/table'

const TableCell = ({ children, parentMdxType }) => {
  if (parentMdxType === 'TableHeader') {
    return <LnTable.TableHeaderCell>{children}</LnTable.TableHeaderCell>
  }

  return <LnTable.TableCell>{children}</LnTable.TableCell>
}

export default TableCell
