import React, { Children, useState } from 'react'
import * as LnTabs from '@jsluna/tabs'

import TableTab from './TableTab'
import Tab from './Tab'

const toKey = title => title?.toString().toLowerCase().split(' ').join('-')

const Tabs = ({ children }) => {
  const reactChildren = Children.toArray(children)

  const firstChild =
    reactChildren && reactChildren.length > 0
      ? reactChildren[0]
      : { props: { title: '' } }

  const initialTabKey = toKey(firstChild.props.title)

  const [activeKey, setActiveKey] = useState(initialTabKey)

  const activeTab = reactChildren.find(
    child => activeKey === toKey(child.props.title),
  )

  return (
    <div className="c-tabs">
      <div className="c-tabs--wrapper">
        <LnTabs.Tabs type="pill" className="c-tabs--header">
          {Children.map(children, child => {
            const tabKey = toKey(child.props.title)

            if (
              child.props.mdxType !== 'TableTab' &&
              child.props.mdxType !== 'Tab'
            ) {
              return child
            }

            return (
              <LnTabs.TabLink
                className="c-tabs--link"
                key={tabKey}
                onClick={() => setActiveKey(tabKey)}
                active={activeKey === tabKey}
                {...child.props}
              >
                {child.props.title}
              </LnTabs.TabLink>
            )
          })}
        </LnTabs.Tabs>
      </div>
      <LnTabs.TabPanel>
        {activeTab && (
          <>
            {activeTab.props.mdxType === 'TableTab' && (
              <TableTab {...activeTab.props} />
            )}
            {activeTab.props.mdxType === 'Tab' && <Tab {...activeTab.props} />}
          </>
        )}
      </LnTabs.TabPanel>
    </div>
  )
}

export default Tabs
