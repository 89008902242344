import { Accordion, AccordionItem } from '@jsluna/accordion';
import { SearchButton } from '@jsluna/button';
import { NectarFlatPurple } from '@jsluna/images';
import { TextInputField, SearchInput } from '@jsluna/form';
import TextImagesDONT from '@images/a11y/1.1-Text-Images-DONT.png';
import LabelInputsDO from '@images/a11y/1.10-Label-Inputs-DO.png';
import LabelInputsDONT from '@images/a11y/1.10-Label-Inputs-DONT.png';
import HiddenLabelDO from '@images/a11y/1.5-Hidden-Label-DO.png';
import HiddenLabelDONT from '@images/a11y/1.5-Hidden-Label-DONT.png';
import TextalternativesDO from '@images/a11y/1.2-Text-alternatives-DO.png';
import TextalternativesDONT from '@images/a11y/1.2-Text-alternatives-DONT.png';
import TextalternativesDO2 from '@images/a11y/1.2-Text-alternatives-DO_2.png';
import TextalternativesDONT2 from '@images/a11y/1.2-Text-alternatives-DONT_2.png';
import TextalternativesDO4 from '@images/a11y/1.2-Text-alternatives-DO_4.png';
import TextalternativesDONT4 from '@images/a11y/1.2-Text-alternatives-DONT_4.png';
import FocusStatesDO from '@images/a11y/1.4-Focus-States-DO.png';
import FocusStatesDONT from '@images/a11y/1.4-Focus-States-DONT.png';
import ReadingFocusOrderDO from '@images/a11y/1.5-Reading-Focus-Order-DO.png';
import ReadingFocusOrderDONT from '@images/a11y/1.5-Reading-Focus-Order-DONT.png';
import HeadingsDO from '@images/a11y/1.6-Headings-DO.png';
import HeadingsDONT from '@images/a11y/1.6-Headings-DONT.png';
import ResponsiveDO from '@images/a11y/1.7-Responsive-DO.png';
import ResponsiveDONT from '@images/a11y/1.7-Responsive-DONT.png';
import ColourContrastDO from '@images/a11y/1.8-ColourContrast-DO.png';
import ColourContrastDONT from '@images/a11y/1.8-ColourContrast-DONT.png';
import UIColourContrastDO from '@images/a11y/1.8-UIColourContrast-DO.png';
import UIColourContrastDONT from '@images/a11y/1.8-UIColourContrast-DONT.png';
import ColourAloneDO from '@images/a11y/1.9-Colour-Alone-DO.png';
import ColourAloneDONT from '@images/a11y/1.9-Colour-Alone-DONT.png';
import ColourAloneDO2 from '@images/a11y/2.4-Colour-Alone-DO_2.png';
import ColourAloneDONT2 from '@images/a11y/2.4-Colour-Alone-DONT_2.png';
import PlaceholderTextDO from '@images/a11y/PlaceholderText-DO.png';
import PlaceholderTextDONT from '@images/a11y/PlaceholderText-DONT.png';
import SupportLargeFontDO from '@images/a11y/SupportLargeFont-DO.png';
import SupportLargeFontDONT from '@images/a11y/SupportLargeFont-DONT.png';
import { OutlineExampleDont, OutlineExampleDo } from '@components/accessibility-sections/OutlineExample';
import { ColourContrast, ImagesOfText, KeyboardTasks, LeftAlignParagraphs, ResponsiveLayout, SemanticMarkup, SentenceCase } from '@components/accessibility-sections';
import { default as CodeSnippet } from '@components/accessibility-sections/CodeSnippet';
import ResponsiveLayoutContent from '@shared/accessibility/responsive-layout';
import * as React from 'react';
export default {
  Accordion,
  AccordionItem,
  SearchButton,
  NectarFlatPurple,
  TextInputField,
  SearchInput,
  TextImagesDONT,
  LabelInputsDO,
  LabelInputsDONT,
  HiddenLabelDO,
  HiddenLabelDONT,
  TextalternativesDO,
  TextalternativesDONT,
  TextalternativesDO2,
  TextalternativesDONT2,
  TextalternativesDO4,
  TextalternativesDONT4,
  FocusStatesDO,
  FocusStatesDONT,
  ReadingFocusOrderDO,
  ReadingFocusOrderDONT,
  HeadingsDO,
  HeadingsDONT,
  ResponsiveDO,
  ResponsiveDONT,
  ColourContrastDO,
  ColourContrastDONT,
  UIColourContrastDO,
  UIColourContrastDONT,
  ColourAloneDO,
  ColourAloneDONT,
  ColourAloneDO2,
  ColourAloneDONT2,
  PlaceholderTextDO,
  PlaceholderTextDONT,
  SupportLargeFontDO,
  SupportLargeFontDONT,
  OutlineExampleDont,
  OutlineExampleDo,
  ColourContrast,
  ImagesOfText,
  KeyboardTasks,
  LeftAlignParagraphs,
  ResponsiveLayout,
  SemanticMarkup,
  SentenceCase,
  CodeSnippet,
  ResponsiveLayoutContent,
  React
};