import React from 'react'
import { Tag } from '@sainsburys-tech/fable'
import { ArrowDown } from '@sainsburys-tech/icons'

export const TagStandardExample = () => {
  return <Tag onClick={function noRefCheck() {}}>Standard Tag</Tag>
}

export const TagIconExample = () => {
  return (
    <Tag
      isSelected
      leadingIcon={<ArrowDown />}
      onClick={function noRefCheck() {}}
    >
      Leading Icon
    </Tag>
  )
}
