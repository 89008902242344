import React from 'react'

import { Accordion, AccordionItem, Link } from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const ImagesOfText = () => (
  <section>
    <h2>Don&apos;t use images of text</h2>

    <p>
      Avoid using images to convey information and never use images in place of
      text.
    </p>

    <Accordion standalone>
      <AccordionItem title="Why not use images of text?">
        <p>Text is preferable to images of text because:</p>

        <ul>
          <li>
            text is an encoded set of unique characters and symbols that can be
            identified programmatically
          </li>
          <li>
            it can be extracted into whatever format or application a user needs
          </li>
          <li>
            it can be highlighted, copied and read by screen readers, search
            engines and other assistive technologies
          </li>
          <li>
            it can be resized and adjusted to different layouts and viewports
          </li>
          <li>
            an image is a collection of pixels that contains no information
          </li>
          <li>
            images are purely visual, and it’s not possible to extract or
            manipulate the content of images with commercially available
            software
          </li>
        </ul>

        <h3>W3C Web Content Accessibility Guideline requirements:</h3>

        <ul>
          <li>
            <Link href="https://www.w3.org/WAI/WCAG22/Understanding/non-text-content.html">
              Understanding Success Criterion 1.1.1: Non-text Content
            </Link>
          </li>
        </ul>
      </AccordionItem>
    </Accordion>

    <CodeSnippet
      type="positive"
      component={
        <>
          <h3>This is real text</h3>
          <p style={{ fontSize: '0.95rem' }}>
            It can be highlighted, copied and read out by a screen reader
          </p>
        </>
      }
      code={`<h3>This is real text</h3>
        <p>
          It can be highlighted, copied and read out by a screen reader.
        </p>`}
    />
    <CodeSnippet
      type="negative"
      component={
        <>
          <img src="../../images/accessibility/text-image.png" alt="" />
        </>
      }
      code={`<img src="/text-image.png" />`}
    />
  </section>
)

export default ImagesOfText
