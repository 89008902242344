import React from 'react'
import {
  Footer,
  FlexibleContentSection,
  FlexibleContent,
  LinkListsSection,
  LinkList,
} from '@sainsburys-tech/fable'

export const BasicFooterExample = () => {
  return (
    <Footer
      brand="sainsburys"
      legalText="lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
      technicalLinks={[
        {
          href: 'http://example.com',
          text: 'Link 1',
        },
        {
          href: 'http://example.com',
          text: 'Link 2',
        },
        {
          href: 'http://example.com',
          text: 'Link 3',
        },
        {
          href: 'http://example.com',
          text: 'Link 4',
        },
        {
          href: 'http://example.com',
          text: 'Link 5',
        },
        {
          href: 'http://example.com',
          text: 'Link 6',
        },
        {
          href: 'http://example.com',
          text: 'Link 7',
        },
        {
          href: 'http://example.com',
          text: 'Link 8',
        },
      ]}
    />
  )
}

export const DarkFooterExample = () => {
  return (
    <Footer
      brand="sainsburys"
      colourScheme="dark"
      legalText="lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
      technicalLinks={[
        {
          href: 'http://example.com',
          text: 'Link 1',
        },
        {
          href: 'http://example.com',
          text: 'Link 2',
        },
        {
          href: 'http://example.com',
          text: 'Link 3',
        },
        {
          href: 'http://example.com',
          text: 'Link 4',
        },
        {
          href: 'http://example.com',
          text: 'Link 5',
        },
        {
          href: 'http://example.com',
          text: 'Link 6',
        },
        {
          href: 'http://example.com',
          text: 'Link 7',
        },
        {
          href: 'http://example.com',
          text: 'Link 8',
        },
      ]}
    />
  )
}

export const ContentSectionsFooterExample = () => {
  return (
    <Footer
      brand="sainsburys"
      legalText="lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
      technicalLinks={[
        {
          href: 'http://example.com',
          text: 'Link 1',
        },
        {
          href: 'http://example.com',
          text: 'Link 2',
        },
        {
          href: 'http://example.com',
          text: 'Link 3',
        },
        {
          href: 'http://example.com',
          text: 'Link 4',
        },
        {
          href: 'http://example.com',
          text: 'Link 5',
        },
        {
          href: 'http://example.com',
          text: 'Link 6',
        },
        {
          href: 'http://example.com',
          text: 'Link 7',
        },
        {
          href: 'http://example.com',
          text: 'Link 8',
        },
      ]}
    >
      <FlexibleContentSection>
        <FlexibleContent
          header={{
            text: 'Section Header',
          }}
        >
          <span>Section Content</span>
        </FlexibleContent>
        <FlexibleContent
          header={{
            text: 'Section Header',
          }}
        >
          <span>Section Content</span>
        </FlexibleContent>
      </FlexibleContentSection>
    </Footer>
  )
}

export const LinkListsFooterExample = () => {
  return (
    <Footer
      brand="sainsburys"
      legalText="lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
      technicalLinks={[
        {
          href: 'http://example.com',
          text: 'Link 1',
        },
        {
          href: 'http://example.com',
          text: 'Link 2',
        },
        {
          href: 'http://example.com',
          text: 'Link 3',
        },
        {
          href: 'http://example.com',
          text: 'Link 4',
        },
        {
          href: 'http://example.com',
          text: 'Link 5',
        },
        {
          href: 'http://example.com',
          text: 'Link 6',
        },
        {
          href: 'http://example.com',
          text: 'Link 7',
        },
        {
          href: 'http://example.com',
          text: 'Link 8',
        },
      ]}
    >
      <LinkListsSection>
        <LinkList
          header={{
            text: 'List Label',
          }}
          links={[
            {
              href: 'http://example.com',
              text: 'Link 1',
            },
            {
              href: 'http://example.com',
              text: 'Link 2',
            },
            {
              href: 'http://example.com',
              text: 'Link 3',
            },
            {
              href: 'http://example.com',
              text: 'Link 4',
            },
            {
              href: 'http://example.com',
              text: 'Link 5',
            },
          ]}
        />
        <LinkList
          header={{
            text: 'List Label',
          }}
          links={[
            {
              href: 'http://example.com',
              text: 'Link 1',
            },
            {
              href: 'http://example.com',
              text: 'Link 2',
            },
            {
              href: 'http://example.com',
              text: 'Link 3',
            },
            {
              href: 'http://example.com',
              text: 'Link 4',
            },
          ]}
        />
        <LinkList
          header={{
            text: 'List Label',
          }}
          links={[
            {
              href: 'http://example.com',
              text: 'Link 1',
            },
            {
              href: 'http://example.com',
              text: 'Link 2',
            },
            {
              href: 'http://example.com',
              text: 'Link 3',
            },
            {
              href: 'http://example.com',
              text: 'Link 4',
            },
          ]}
        />
        <LinkList
          header={{
            text: 'List Label',
          }}
          links={[
            {
              href: 'http://example.com',
              text: 'Link 1',
            },
            {
              href: 'http://example.com',
              text: 'Link 2',
            },
            {
              href: 'http://example.com',
              text: 'Link 3',
            },
          ]}
        />
      </LinkListsSection>
      <FlexibleContentSection>
        <FlexibleContent
          header={{
            text: 'Section Header',
          }}
        >
          <span>Section Content</span>
        </FlexibleContent>
        <FlexibleContent
          header={{
            text: 'Section Header',
          }}
        >
          <span>Section Content</span>
        </FlexibleContent>
      </FlexibleContentSection>
    </Footer>
  )
}
