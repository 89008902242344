import React, { Children } from 'react'
import * as LnTable from '@jsluna/table'

import TableBody from './TableBody'
import TableHeader from './TableHeader'

export const TableTab = ({ children, caption, visuallyHiddenCaption }) => {
  return (
    <LnTable.TableContainer>
      {caption && (
        <LnTable.TableCaption visuallyHidden={visuallyHiddenCaption}>
          {caption}
        </LnTable.TableCaption>
      )}

      {Children.map(children, child => {
        return (
          <>
            {child.props.mdxType === 'TableHeader' && (
              <TableHeader {...child.props}>{child.props.children}</TableHeader>
            )}
            {child.props.mdxType === 'TableBody' && (
              <TableBody {...child.props}>{child.props.children}</TableBody>
            )}
          </>
        )
      })}
    </LnTable.TableContainer>
  )
}

export default TableTab
