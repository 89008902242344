import React from 'react'
import { EnergyLabelBadge } from '@sainsburys-tech/fable'
import { Container, GridItem, GridWrapper } from '@sainsburys-tech/grid'

export const EnergyLabelExample = () => {
  return (
    <Container>
      <GridWrapper numberOfItems={7} cols={12}>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="A"
              testid="energy-rating"
            />
          </div>
        </GridItem>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="B"
              testid="energy-rating"
            />
          </div>
        </GridItem>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="C"
              testid="energy-rating"
            />
          </div>
        </GridItem>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="D"
              testid="energy-rating"
            />
          </div>
        </GridItem>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="E"
              testid="energy-rating"
            />
          </div>
        </GridItem>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="F"
              testid="energy-rating"
            />
          </div>
        </GridItem>
        <GridItem
          colSpan={{
            lg: 3,
            md: 6,
            sm: 6,
          }}
        >
          <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
            <EnergyLabelBadge
              onClick={function noRefCheck() {}}
              ratingType="G"
              testid="energy-rating"
            />
          </div>
        </GridItem>
      </GridWrapper>
    </Container>
  )
}

export const EnergyLabelSizeExample = () => {
  return (
    <GridWrapper numberOfItems={2}>
      <GridItem>
        <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
          <div>1. Medium</div>
          <EnergyLabelBadge
            onClick={function noRefCheck() {}}
            ratingType="A"
            testid="energy-rating"
            size="md"
          />
        </div>
      </GridItem>
      <GridItem>
        <div className="ds-flex ds-flex-col ds-justify-center ds-items-center">
          <div>2. Small</div>
          <EnergyLabelBadge
            onClick={function noRefCheck() {}}
            ratingType="A"
            testid="energy-rating"
            size="sm"
          />
        </div>
      </GridItem>
    </GridWrapper>
  )
}
