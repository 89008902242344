import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const MarkdownLink = ({ href, ...rest }) => {
  if (href) {
    if (href.startsWith('/')) {
      return (
        <Link
          to={href.endsWith('/') ? href : `${href}/`}
          className="ln-c-link"
          {...rest}
        />
      )
    }

    if (!href.startsWith('http')) {
      return <a className="ln-c-link" href={href} {...rest} /> // eslint-disable-line jsx-a11y/anchor-has-content
    }
  }
  return (
    <a
      className="ln-c-link"
      href={href}
      target="_blank"
      rel="noopener noreferrer nofollow"
      {...rest}
    />
  )
}

MarkdownLink.propTypes = {
  href: PropTypes.string.isRequired,
}

export default MarkdownLink
