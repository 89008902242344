import React from 'react'
import Card from '@components/card'
import Color from 'color'

const ColourBlock = ({
  children,
  center,
  fill,
  fontColour,
  opacity,
  hideValues = false,
}) => {
  const colour = opacity ? Color(fill)?.alpha(opacity / 100) : Color(fill)
  const rgb = colour.rgb().string()

  const styles = {
    backgroundColor: colour,
    textAlign: center && `center`,
    color: fontColour ? fontColour : Color(colour).isLight() ? '#000' : '#FFF',
    border: 'none',
  }
  return (
    <Card style={styles} className="c-card c-colour-block">
      <strong>{children}</strong>
      {!hideValues && (
        <>
          <span className="o-easy-select ln-u-display-block">
            {fill} {opacity && `${opacity}%`}
          </span>
          <span className="o-easy-select ln-u-display-block">{rgb}</span>
        </>
      )}
    </Card>
  )
}

export default ColourBlock
