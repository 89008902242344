export const formatTableText = str => {
  return str.charAt(0).toUpperCase().concat(str.slice(1)).replace('-', ' ')
}

export const responsiveScreenSizes = fontStyle => {
  const responsiveStyles = [
    { style: 'display-5', alt: 'display-6' },
    { style: 'display-4', alt: 'display-5' },
    { style: 'display-3', alt: 'display-4' },
    { style: 'display-2', alt: 'display-3' },
    { style: 'display-1', alt: 'display-2' },
  ]

  const responsiveStyle = responsiveStyles.find(x => x.style === fontStyle)

  return responsiveStyle?.alt
}


export const formatFontName = ({ fontFamily, fontWeight }) => {
  const formatName = fontFamily
    .split(',')[0]
    .replace(/'/g, '')
    .replace(/([A-Z])/g, ' $1')
    .trim()

  return `${formatName} ${fontWeight}`.trim()
}
