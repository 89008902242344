import React from 'react'

import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display4 } from '@jsluna/typography'
import { Section } from '@jsluna/section'

import Card from '@components/card'

import links from '@utils/externalLinks'

import EmailIcon from '@images/contact/email.svg'
import SlackIcon from '@images/contact/slack.svg'
import TeamsIcon from '@images/contact/teams.svg'
import AnchorLinkIcon from '@images/icons/link_icon.svg'

const GetInTouch = ({ children }) => {
  return (
    <Section className="c-get-in-touch">
      <Display4 element="h2" id="get-in-touch" style={{ position: 'relative' }}>
        <a
          className="c-title-link__anchor before"
          href="#get-in-touch"
          aria-label="get in contact permalink"
        >
          <AnchorLinkIcon />
        </a>
        Get in touch
      </Display4>
      <div>{children}</div>
      <GridWrapper equalHeight={true}>
        <GridItem
          size={{ md: '1/3' }}
          className="ln-u-display-flex c-get-in-touch--item"
        >
          <a
            href={`mailto:${links.email}?subject=Luna`}
            target="_blank"
            rel="noreferrer"
            className="ln-u-display-flex"
          >
            <Card className="c-card--center">
              <div>
                <EmailIcon />
              </div>
              <div>
                <strong>Email</strong>
              </div>
            </Card>
          </a>
        </GridItem>
        <GridItem size={{ md: '1/3' }} className="c-get-in-touch--item">
          <a
            href={links.teams.designSystemsHub}
            target="_blank"
            rel="noreferrer"
            className="ln-u-display-flex"
          >
            <Card className="c-card--center">
              <div style={{ padding: '1rem' }}>
                <TeamsIcon />
              </div>
              <div>
                <strong>Microsoft Teams</strong>
              </div>
            </Card>
          </a>
        </GridItem>
        <GridItem size={{ md: '1/3' }} className="c-get-in-touch--item">
          <a
            href={links.slack.designSystemsHub}
            target="_blank"
            rel="noreferrer"
            className="ln-u-display-flex"
          >
            <Card className="c-card--center">
              <div style={{ padding: '1rem' }}>
                <SlackIcon />
              </div>
              <div>
                <strong>Slack</strong>
              </div>
            </Card>
          </a>
        </GridItem>
      </GridWrapper>
    </Section>
  )
}

export default GetInTouch
