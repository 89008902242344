import GlobalTokens from '@sainsburys-tech/design-tokens/dist/global/json/tokens.json';
import SainsburysColourTokens from '@sainsburys-tech/tokens/dist/sainsburys/js/json/color/index.json';
import SainsburysTypeTokens from '@sainsburys-tech/tokens/dist/sainsburys/js/json/typography/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup } from '@components/design-tokens';
import FurtherReading from '@shared/colours/further-reading';
import * as React from 'react';
export default {
  GlobalTokens,
  SainsburysColourTokens,
  SainsburysTypeTokens,
  ColourBlock,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  PrimaryColours,
  SecondaryColours,
  PromotionalColours,
  BaseColours,
  ExtendedColours,
  MonochromeColours,
  SemanticColours,
  ColourLookup,
  FurtherReading,
  React
};