import React from 'react'

const defaultState = {
  brand: 'sainsburys',
  punctuatedBrand: "Sainsbury's",
  setBrand: brand => {},
  setPunctuatedBrand: punctuatedBrand => {},
}

const ThemeContext = React.createContext(defaultState)

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches === true

class ThemeProvider extends React.Component {
  state = {
    brand: 'sainsburys',
    punctuatedBrand: "Sainsbury's",
  }

  setBrand = brand => {
    localStorage.setItem('brand', JSON.stringify(brand))
    this.setState({ brand })
  }

  setPunctuatedBrand = punctuatedBrand => {
    localStorage.setItem('punctuatedBrand', JSON.stringify(punctuatedBrand))
    this.setState({ punctuatedBrand })
  }

  componentDidMount() {
    // Getting dark mode value from localStorage!
    const lsDark = JSON.parse(localStorage.getItem('dark'))
    if (lsDark) {
      this.setState({ dark: lsDark })
    } else if (supportsDarkMode()) {
      this.setState({ dark: true })
    }
  }

  render() {
    const { children } = this.props
    const { brand } = this.state
    const { punctuatedBrand } = this.state
    return (
      <ThemeContext.Provider
        value={{
          brand,
          setBrand: this.setBrand,
          punctuatedBrand,
          setPunctuatedBrand: this.setPunctuatedBrand,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
