import React from 'react'
import { InputRange } from '@sainsburys-tech/fable'

export const InputRangeExample = () => {
  return (
    <InputRange
      label="Label"
      onChange={function noRefCheck() {}}
      options={[
        {
          label: 'Copy',
          value: '0',
        },
        {
          label: 'Copy',
          value: '100',
        },
      ]}
      step="any"
    />
  )
}

export const InputRangeSupportingTextExample = () => {
  return (
    <InputRange
      label="Label"
      onChange={function noRefCheck() {}}
      supportingText="Supporting text"
      options={[
        {
          label: 'Copy',
          value: '0',
        },
        {
          label: 'Copy',
          value: '100',
        },
      ]}
      step="any"
    />
  )
}
