const links = {
  email: 'design-systems@sainsburys.co.uk',
  feedback: 'https://forms.office.com/r/En4eB3SB9t',
  figma: {
    community: 'https://www.figma.com/@sainsburys',
  },
  luna: {
    devDocs: 'https://sainsburys-tech.github.io/design-systems/',
    old: 'https://luna.sainsburys.co.uk/guidelines',
    hostingAccess:
      'https://design-systems-token-requests-fe.int.prd.jspaas.uk/',
  },
  slack: {
    designSystemsHub: 'https://sainsburys-tech.slack.com/archives/C0109KPBCR0',
  },
  fable: {
    fableGettingStarted: 'https://sainsburys-tech.github.io/design-systems/?path=/docs/documentation-quick-start--quickstart',
    storybook: 'https://sainsburys-tech.github.io/design-systems'
  },
  teams: {
    designSystemsHub:
      'https://teams.microsoft.com/l/team/19%3aaad9802e55b146dab1e3d2fb4a0fc52d%40thread.skype/conversations?groupId=a5c53840-8b6c-4d88-a798-8e2550ac34ef&tenantId=e11fd634-26b5-47f4-8b8c-908e466e9bdf',
  },
  privacy: {
    cookiePolicy: 'https://privacy-hub.sainsburys.co.uk/cookie-policy/',
    privacyHub: 'https://privacy-hub.sainsburys.co.uk/',
  },
}

export default links
