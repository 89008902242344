import React from 'react'
import { Button, IconButton } from '@sainsburys-tech/fable'
import { Delete, Trolley, Cancel } from '@sainsburys-tech/icons'

const PrimaryButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <Button onClick={function noRefCheck() {}} variant="primary">
        Add to trolley
      </Button>
    </div>
  )
}

export const SecondaryButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <Button onClick={function noRefCheck() {}} variant="secondary">
        Add to trolley
      </Button>
    </div>
  )
}

export const TertiaryButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <Button onClick={function noRefCheck() {}} variant="tertiary">
        Add to trolley
      </Button>
    </div>
  )
}

export const FullWidthButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <Button onClick={function noRefCheck() {}} variant="primary" isFullWidth>
        Add to trolley
      </Button>
    </div>
  )
}

export const CompactButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <IconButton
        onClick={function noRefCheck() {}}
        variant="tertiary"
        size="xs"
        icon={<Cancel />}
        label="Close"
      />
    </div>
  )
}

export const LightButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <Button
        colourScheme="monochrome-light"
        onClick={function noRefCheck() {}}
        variant="primary"
      >
        Add to trolley
      </Button>
    </div>
  )
}

export const DarkButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <Button
        colourScheme="monochrome-dark"
        onClick={function noRefCheck() {}}
        variant="primary"
      >
        Add to trolley
      </Button>
    </div>
  )
}

export const DisabledButtonsExample = () => {
  return (
    <div className="ds-flex ds-flex-col ds-gap-2 ds-justify-center ds-items-center">
      <Button hasDisabledStyle onClick={function noRefCheck() {}}>
        Primary Disabled
      </Button>
      <Button
        hasDisabledStyle
        onClick={function noRefCheck() {}}
        variant="secondary"
      >
        Secondary Disabled
      </Button>
      <Button
        hasDisabledStyle
        onClick={function noRefCheck() {}}
        variant="tertiary"
      >
        Tertiary Disabled
      </Button>
    </div>
  )
}

export const IconButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <IconButton
        hasHiddenText
        icon={<Delete />}
        label="Delete"
        onClick={function noRefCheck() {}}
        variant="primary"
      />
    </div>
  )
}

export const IconTextButtonExample = () => {
  return (
    <div className="ds-flex ds-justify-center ds-items-center">
      <IconButton
        icon={<Trolley />}
        label="Trolley"
        onClick={function noRefCheck() {}}
        variant="primary"
      >
        Trolley
      </IconButton>
    </div>
  )
}

export default PrimaryButtonExample
