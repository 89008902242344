import React from 'react'
import PropTypes from 'prop-types'

import ReactDOMServer from 'react-dom/server'

import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwl'

const prettier = require('prettier/standalone')
const prettierHtml = require('prettier/parser-html')
const prettierCss = require('prettier/parser-postcss')

const CodeSnippet = props => {
  const { component, codeFormat, noOverflow } = props
  let { code } = props

  if (typeof code === 'undefined') {
    code = ReactDOMServer.renderToStaticMarkup(component)
  }

  if (code) {
    switch (codeFormat) {
      case 'css':
        code = prettier.format(code, {
          parser: 'css',
          plugins: [prettierCss],
        })
        break
      case 'clike':
        break
      default:
        code = prettier.format(code, {
          parser: 'html',
          plugins: [prettierHtml],
        })
        break
    }
  }

  return (
    <div
      className={`c-card--code-snippet ln-u-flex-grow ln-u-display-flex ln-u-flex-direction-col ${
        noOverflow ? 'no-overflow' : ''
      }`}
    >
      <div class="ln-u-soft">{component}</div>
      {code !== null ? (
        <Highlight
          {...defaultProps}
          theme={theme}
          language={codeFormat}
          code={code}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={`${className}`} style={style}>
              <code>
                {tokens.map((line, i) => (
                  <span key={i} {...getLineProps({ line, key: i })}>
                    <span className="token-line-number">{i + 1}</span>
                    <span className="token-line-content">
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </span>
                  </span>
                ))}
              </code>
            </pre>
          )}
        </Highlight>
      ) : null}
    </div>
  )
}

CodeSnippet.propTypes = {
  code: PropTypes.string,
  codeFormat: PropTypes.string,
  classes: PropTypes.shape({ root: PropTypes.string }),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
}

CodeSnippet.defaultProps = {
  codeFormat: 'html',
}

export default CodeSnippet
