import GlobalTokens from '@sainsburys-tech/design-tokens/dist/global/json/tokens.json';
import ColourBlock from '@components/colour-block';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup, TableCellResult } from '@components/design-tokens';
import FurtherReading from '@shared/colours/further-reading';
import * as React from 'react';
export default {
  GlobalTokens,
  ColourBlock,
  PrimaryColours,
  SecondaryColours,
  PromotionalColours,
  BaseColours,
  ExtendedColours,
  MonochromeColours,
  SemanticColours,
  ColourLookup,
  TableCellResult,
  FurtherReading,
  React
};