import React, { Children } from 'react'
import * as LnTable from '@jsluna/table'
import TableCell from './TableCell'
import { TableCellResult } from '../design-tokens'

const TableRow = ({ children, mdxType }) => {
  if (mdxType === 'TableHeader') {
    return (
      <LnTable.TableHeaderRow>
        {Children.map(children, child => {
          return (
            <TableCell parentMdxType={mdxType} {...child.props}>
              {child.props.children}
            </TableCell>
          )
        })}
      </LnTable.TableHeaderRow>
    )
  }

  return (
    <LnTable.TableRow>
      {Children.map(children, child => {
        return (
          <>
            {child.props.mdxType === 'TableCell' && (
              <TableCell parentMdxType={mdxType} {...child.props}>
                {child.props.children}
              </TableCell>
            )}
            {child.props.mdxType === 'TableCellResult' && (
              <TableCellResult {...child.props}>
                {child.props.children}
              </TableCellResult>
            )}
          </>
        )
      })}
    </LnTable.TableRow>
  )
}

export default TableRow
