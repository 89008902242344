import React from 'react'
import { TableHeader, TableHeaderRow, TableHeaderCell } from '@jsluna/table'

const ColourTableHeader = () => {
  return (
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Token</TableHeaderCell>
        <TableHeaderCell>Value</TableHeaderCell>
        <TableHeaderCell>Example</TableHeaderCell>
      </TableHeaderRow>
    </TableHeader>
  )
}

export default ColourTableHeader
