import React from 'react'
import { Rating } from '@sainsburys-tech/fable'

export const SmallRating = () => {
  return (
    <Rating size="sm" hasDivider rating={4.9} showRating colourScheme="colour">
      Recommended by 91% of customers
    </Rating>
  )
}

export const LargeRating = () => {
  return (
    <Rating size="lg" hasDivider rating={4.9} showRating>
      Recommended by 91% of customers
    </Rating>
  )
}

export const StarRating = () => {
  return (
    <Rating rating={3} showRating>
      Recommended by 60% of customers
    </Rating>
  )
}

export const BarRating = () => {
  return <Rating variant="secondary" rating={4} showRating></Rating>
}

export const MonochromeStarRating = () => {
  return (
    <Rating colourScheme="monochrome" rating={3} showRating>
      Recommended by 60% of customers
    </Rating>
  )
}
