import React from 'react'
import { TableRow, TableCell } from '@jsluna/table'

const getValueCellStyle = (type, value) => {
  switch (type) {
    case 'color':
      return {
        textTransform: 'uppercase',
        userSelect: 'all',
      }
    case 'modifier':
      return {
        textTransform: value.toString().startsWith('#') && 'uppercase',
      }
    default:
      return
  }
}

const ColourCircle = ({ fill }) => {
  const styles = {
    backgroundColor: fill,
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    border: '1px solid var(--ds-color-monochrome-dark)',
  }

  return <div style={styles} />
}

const TokenTableRow = ({ token, value, type }) => {
  const valueCellStyle = getValueCellStyle(type, value)

  return (
    <TableRow>
      <TableCell>{token}</TableCell>

      <TableCell style={valueCellStyle}>{value}</TableCell>

      {type === 'color' && (
        <TableCell>
          <ColourCircle fill={value} />
        </TableCell>
      )}
    </TableRow>
  )
}

const TokenTableRows = ({ tokens, selectTokens, type }) => {
  return tokens.map(token => {
    const brandToken = selectTokens[token]

    if (!brandToken) {
      return null
    }

    if (typeof brandToken !== 'object') {
      return (
        <TokenTableRow
          key={token}
          token={token}
          value={brandToken}
          type={type}
        />
      )
    }

    return Object.keys(brandToken).map(childToken => {
      const tokenKey = brandToken[childToken]

      const childBrandToken = `${token}-${childToken}`

      if (typeof tokenKey === 'object') {
        return Object.keys(tokenKey).map(innerModifierKey => {
          const innerToken = `${childBrandToken}-${innerModifierKey}`

          return (
            <TokenTableRow
              key={innerToken}
              token={innerToken}
              value={tokenKey[innerToken]}
              type={type}
            />
          )
        })
      }

      return (
        <TokenTableRow
          key={childBrandToken}
          token={childBrandToken}
          value={tokenKey}
          type={type}
        />
      )
    })
  })
}

export default TokenTableRows
