import React from 'react'
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/breadcrumbs'

const ColourBreadcrumbs = ({ title, brand }) => {
  return (
    <BreadcrumbsWrapper className="c-breadcrumbs--wrapper">
      <BreadcrumbsItem className="c-breadcrumbs--item">{brand}</BreadcrumbsItem>
      <BreadcrumbsItem className="c-breadcrumbs--item">Colour</BreadcrumbsItem>
      <BreadcrumbsItem className="c-breadcrumbs--item">{title}</BreadcrumbsItem>
    </BreadcrumbsWrapper>
  )
}

export default ColourBreadcrumbs
