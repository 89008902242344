import React from 'react'

import { GridWrapper, Accordion, AccordionItem } from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const LeftAlignParagraphs = () => (
  <section>
    <h2>Left align paragraphs of text</h2>

    <p>
      Avoid justified and centre aligning text, unless it’s a heading or a
      control label that fits on one line.
    </p>

    <Accordion standalone>
      <AccordionItem title="Why should body text content be left aligned?">
        <p>Body text should be left aligned because:</p>
        <ul>
          <li>predictable eye movement reduces cognitive load</li>
          <li>
            multiple lines of centre aligned text make it harder to predict the
            start of each line, making it harder to scan
          </li>
          <li>
            multiple lines of centre aligned text can be unpredictable and
            frustrating to navigate for users who are zoomed in{' '}
          </li>
          <li>
            unpredictable spacing in justified blocks of text with can be hard
            for some users to read
          </li>
        </ul>
      </AccordionItem>
    </Accordion>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        code={null}
        component={
          <div className="ln-u-border ln-u-soft">
            <p>This section of text is left aligned.</p>
            <p>
              The start of each line and the spacing between words is
              predictable and consistent which makes scanning and reading
              easier.
            </p>
          </div>
        }
      />
      <CodeSnippet
        type="negative"
        code={null}
        component={
          <div className="ln-u-border ln-u-soft">
            <p className="ln-u-text-align-center">
              This section of text is centre aligned.
            </p>
            <p className="ln-u-text-align-center">
              The start of each line is unpredictable which makes scanning and
              reading harder and can be very disorientating for some users.
            </p>
          </div>
        }
      />
    </GridWrapper>
  </section>
)

export default LeftAlignParagraphs
