import React from 'react'
import { Stepper } from '@sainsburys-tech/fable'

export const StepperHorizontalExample = () => {
  return (<Stepper
  orientation="horizontal"
  steps={[
    {
      label: 'Title 1',
      status: 'complete'
    },
    {
      label: 'Title 2',
      status: 'complete'
    },
    {
      label: 'Title 3',
      status: 'active'
    },
    {
      label: 'Title 4'
    },
    {
      label: 'Title 5'
    }
  ]}
/>
  )
}

export const StepperVerticalExample = () => {
  return (<Stepper
  orientation="vertical"
  steps={[
    {
      label: 'Title 1',
      status: 'complete'
    },
    {
      label: 'Title 2',
      status: 'complete'
    },
    {
      label: 'Title 3',
      status: 'active'
    },
    {
      label: 'Title 4'
    },
    {
      label: 'Title 5'
    }
  ]}
/>
  )
}