import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
} from '@jsluna/button'
import { Section } from '@jsluna/section'
import { Box as Box1 } from '@sainsburys-tech/fable'
import Button from '../button'
import MarkdownLink from '../markdown-link'
import GridList, { GridListItem } from '../grid-list'

import {
  PrimaryAlertExampleBundle,
  SecondaryAlertExampleBundle,
  StandardAlertExample,
  StandardAlertDismissableExample,
  InformationAlertExample,
  ErrorAlertExample,
  WarningAlertExample,
  SuccessAlertExample,
} from '../Fable/alert-example'
import {
  AccordionExample,
  AccordionWithFooterExample,
} from '../Fable/accordion-example'
import BreadCrumbsExample, {
  BreadcrumbsCompactExample,
} from '../Fable/breadcrumbs-example'
import PrimaryButtonExample, {
  SecondaryButtonExample,
  TertiaryButtonExample,
  FullWidthButtonExample,
  LightButtonExample,
  DarkButtonExample,
  DisabledButtonsExample,
  IconButtonExample,
  IconTextButtonExample,
} from '../Fable/button-example'
import CheckboxExample from '../Fable/checkbox-examples'
import InlineLinkExample, {
  StandaloneLinkExample,
  BrandedLinkExample,
} from '../Fable/link-examples'
import ListExample, { InlineListExample } from '../Fable/list-examples'
import ModalExample from '../Fable/modal-example'
import PaginationExample, {
  CompactPaginationExample,
} from '../Fable/pagination-example'
import PasswordInputExample, {
  PasswordInputWIthVisibilityToggle,
} from '../Fable/pasword-input-example'
import RadioGroupExample, {
  OutlinedRadioGroupExample,
  ErroredRadioGroupExample,
  InlineRadioGroupExample,
  StandaloneRadioButton,
} from '../Fable/radio-buttons'
import {
  SmallRating,
  LargeRating,
  BarRating,
  StarRating,
  MonochromeStarRating,
} from '../Fable/rating-examples'
import { SelectExample } from '../Fable/select-example'
import SwitchExample, {
  OutlinedSwitchExample,
  ErrorSwitchExample,
  DisabledSwitchExample,
  StandaloneSwitchExample,
  WithTextSwitchExample,
} from '../Fable/switch-example'
import {
  StepperHorizontalExample,
  StepperVerticalExample,
} from '../Fable/stepper-example'
import { PrimaryTabsExample, SecondaryTabsExample } from '../Fable/tabs-example'
import { TagIconExample, TagStandardExample } from '../Fable/tag-examples'
import {
  StandardInputExample,
  RequiredInputExample,
  SupportingTextInputExample,
  PlaceholderTextInputExample,
  ErrorMessageTextInputExample,
  IconInputExample,
  ButtonInputExample,
} from '../Fable/input-examples'
import {
  ToggleButtonExample,
  IconToggleButtonGroup,
  MixedToggleButtonGroup,
} from '../Fable/toggle-button-example'
import {
  ProgressSpinnerExample,
  ProgressBarExample,
  DeterminateProgressSpinnerExample,
} from '../Fable/loading-indicator-examples'
import {
  ToggleTipLabelExample,
  ToggleTipParagraphExample,
  DoToggleTipExample1,
  DontToggleTipExample1,
} from '../Fable/toggle-tip-example'
import ExampleBox from '../component-display-box'
import {
  BasicFooterExample,
  DarkFooterExample,
  ContentSectionsFooterExample,
  LinkListsFooterExample,
} from '../Fable/footer-example'
import DatePickerExample from '../Fable/date-picker-example'
import HeaderExample from '../Fable/header-example'
import {
  CarouselExample,
  CarouselExampleLink,
  CarouselExampleSmallHeader,
  CarouselExampleBigHeader,
} from '../Fable/carousel-example'
import BoxExample from '../Fable/box-example'
import {
  InputRangeExample,
  InputRangeSupportingTextExample,
} from '../Fable/input-range-example'
import {
  CounterHorizontalExample,
  CounterVerticalExample,
  CounterBinIconExample,
} from '../Fable/counter-example'
import {
  CheckboxGroupVertical,
  CheckboxGroupHorizontal,
} from '../Fable/checkbox-group-example'
import {
  ComboBoxMultiSelect,
  ComboBoxMultiSelectWithSelections,
  ComboBoxMultiSelectButton,
  ComboBoxSingleSelect,
} from '../Fable/combobox-example'
import {
  ColourSwatchLinkExample,
  ColourSwatchRadioExample,
  ColourSwatchColourExample,
  ColourSwatchMetalExample,
  ColourSwatchWoodExample,
} from '../Fable/colour-swatch-example'
import {
  EnergyLabelExample,
  EnergyLabelSizeExample,
} from '../Fable/energy-label-example'
import {
  ProductCardVerticalExample,
  ProductCardHorizontalExample,
  BadgeExample,
  RatingExample,
  ButtonExample,
  ProductCardIconButtonExample,
  PriceExample,
} from '../Fable/product-card-example'
import {
  SingleLevelMenuExample,
  TwoLevelMenuExample,
  ThreeLevelMenuExample,
  DividerMenuExample,
} from '../Fable/menu-example'

import GetInTouch from '../get-in-touch'
import {
  Tabs,
  Tab,
  TableTab,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '../tabs'

import { TableCellResult } from '../design-tokens'

import links from '@utils/externalLinks'
import TableOfContents from '@components/table-of-contents'
import { Guideline, Do, Dont } from '@components/guideline'

import { Button as FbButton } from '@sainsburys-tech/fable'

const Partial = ({ children }) => {
  return (
    <MDXProvider
      components={{
        a: MarkdownLink,
        Button,
        ButtonGroupWrapper,
        ButtonGroupPrimary,
        ButtonGroupSecondary,
        GridList,
        GridListItem,
        links,
        TableOfContents,
        Guideline,
        Do,
        Dont,
        Box1,
        GetInTouch,
        Section,
        Tabs,
        Tab,
        TableTab,
        TableHeader,
        TableBody,
        TableRow,
        TableCell,
        TableCellResult,
        FbButton,
        AccordionExample,
        AccordionWithFooterExample,
        BreadCrumbsExample,
        BreadcrumbsCompactExample,
        PrimaryButtonExample,
        SecondaryButtonExample,
        TertiaryButtonExample,
        FullWidthButtonExample,
        LightButtonExample,
        DarkButtonExample,
        DisabledButtonsExample,
        CheckboxExample,
        InlineLinkExample,
        BrandedLinkExample,
        StandaloneLinkExample,
        ListExample,
        ModalExample,
        PaginationExample,
        PasswordInputExample,
        PasswordInputWIthVisibilityToggle,
        RadioGroupExample,
        SmallRating,
        LargeRating,
        BarRating,
        StarRating,
        SelectExample,
        SwitchExample,
        StepperHorizontalExample,
        StepperVerticalExample,
        PrimaryTabsExample,
        SecondaryTabsExample,
        WithTextSwitchExample,
        TagIconExample,
        TagStandardExample,
        StandardInputExample,
        RequiredInputExample,
        SupportingTextInputExample,
        PlaceholderTextInputExample,
        ErrorMessageTextInputExample,
        IconInputExample,
        ButtonInputExample,
        ToggleButtonExample,
        IconToggleButtonGroup,
        MixedToggleButtonGroup,
        ToggleTipLabelExample,
        ToggleTipParagraphExample,
        DoToggleTipExample1,
        DontToggleTipExample1,
        ProgressSpinnerExample,
        ProgressBarExample,
        DeterminateProgressSpinnerExample,
        IconButtonExample,
        IconTextButtonExample,
        CompactPaginationExample,
        OutlinedSwitchExample,
        ErrorSwitchExample,
        DisabledSwitchExample,
        StandaloneSwitchExample,
        ExampleBox,
        BasicFooterExample,
        DarkFooterExample,
        ContentSectionsFooterExample,
        LinkListsFooterExample,
        InlineListExample,
        OutlinedRadioGroupExample,
        ErroredRadioGroupExample,
        InlineRadioGroupExample,
        MonochromeStarRating,
        StandaloneRadioButton,
        DatePickerExample,
        HeaderExample,
        CarouselExample,
        CarouselExampleLink,
        CarouselExampleSmallHeader,
        CarouselExampleBigHeader,
        BoxExample,
        InputRangeExample,
        InputRangeSupportingTextExample,
        CounterHorizontalExample,
        CounterVerticalExample,
        CounterBinIconExample,
        CheckboxGroupVertical,
        CheckboxGroupHorizontal,
        ComboBoxMultiSelect,
        ComboBoxMultiSelectWithSelections,
        ComboBoxMultiSelectButton,
        ComboBoxSingleSelect,
        PrimaryAlertExampleBundle,
        SecondaryAlertExampleBundle,
        StandardAlertExample,
        StandardAlertDismissableExample,
        InformationAlertExample,
        ErrorAlertExample,
        WarningAlertExample,
        SuccessAlertExample,
        ColourSwatchLinkExample,
        ColourSwatchRadioExample,
        ColourSwatchColourExample,
        ColourSwatchMetalExample,
        ColourSwatchWoodExample,
        EnergyLabelExample,
        EnergyLabelSizeExample,
        ProductCardVerticalExample,
        ProductCardHorizontalExample,
        BadgeExample,
        RatingExample,
        ButtonExample,
        ProductCardIconButtonExample,
        PriceExample,
        SingleLevelMenuExample,
        TwoLevelMenuExample,
        ThreeLevelMenuExample,
        DividerMenuExample,
      }}
    >
      {children}
    </MDXProvider>
  )
}

export default Partial
