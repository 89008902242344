import LabelDo from '@images/a11y/Native-label-1.1-Do.png';
import LabelDont from '@images/a11y/Native-label-1.1-Dont.png';
import LabelNameDo from '@images/a11y/Native-label-2.1–Do.png';
import LabelNameDont from '@images/a11y/Native-label-2.1–Dont.png';
import LinkTextDo from '@images/a11y/Native-link-text-1.4-Do.png';
import LinkTextDont from '@images/a11y/Native-link-text-1.4-Dont.png';
import DecorativeImageDo from '@images/a11y/Native-decorative-image-1.5-Do.png';
import DecorativeImageDont from '@images/a11y/Native-decorative-image-1.5-Dont.png';
import TouchTargetDo from '@images/a11y/Native-touch-target-1.6-Do.png';
import TouchTargetDont from '@images/a11y/Native-touch-target-1.6-Dont.png';
import ColourAloneDO2 from '@images/a11y/2.4-Colour-Alone-DO_2.png';
import ColourAloneDONT2 from '@images/a11y/2.4-Colour-Alone-DONT_2.png';
import LabelInputsDo from '@images/a11y/PlaceholderText-DO.png';
import LabelInputsDont from '@images/a11y/PlaceholderText-DONT.png';
import HiddenLabelDO from '@images/a11y/1.5-Hidden-Label-DO.png';
import HiddenLabelDONT from '@images/a11y/1.5-Hidden-Label-DONT.png';
import ReadingFocusOrderDo from '@images/a11y/1.5-Reading-Focus-Order-DO.png';
import ReadingFocusOrderDont from '@images/a11y/1.5-Reading-Focus-Order-DONT.png';
import LargeFontDo from '@images/a11y/SupportLargeFont-DO1.png';
import LargeFontDont from '@images/a11y/SupportLargeFont-DONT1.png';
import ColourContrastDo from '@images/a11y/1.8-ColourContrast-DO.png';
import ColourContrastDont from '@images/a11y/1.8-ColourContrast-DONT.png';
import UIColourContrastDo from '@images/a11y/1.8-UIColourContrast-DO.png';
import UIColourContrastDont from '@images/a11y/1.8-UIColourContrast-DONT.png';
import TextAlternativesDO from '@images/a11y/1.2-Text-alternatives-DO.png';
import TextAlternativesDONT from '@images/a11y/1.2-Text-alternatives-DONT.png';
import TextAlternativesDO2 from '@images/a11y/1.2-Text-alternatives-DO_2.png';
import TextAlternativesDONT2 from '@images/a11y/1.2-Text-alternatives-DONT_2.png';
import TextAlternativesDO4 from '@images/a11y/1.2-Text-alternatives-DO_4.png';
import TextAlternativesDONT4 from '@images/a11y/1.2-Text-alternatives-DONT_4.png';
import { Accordion, AccordionItem } from '@jsluna/accordion';
import { default as CodeSnippet } from '@components/accessibility-sections/CodeSnippet';
import * as React from 'react';
export default {
  LabelDo,
  LabelDont,
  LabelNameDo,
  LabelNameDont,
  LinkTextDo,
  LinkTextDont,
  DecorativeImageDo,
  DecorativeImageDont,
  TouchTargetDo,
  TouchTargetDont,
  ColourAloneDO2,
  ColourAloneDONT2,
  LabelInputsDo,
  LabelInputsDont,
  HiddenLabelDO,
  HiddenLabelDONT,
  ReadingFocusOrderDo,
  ReadingFocusOrderDont,
  LargeFontDo,
  LargeFontDont,
  ColourContrastDo,
  ColourContrastDont,
  UIColourContrastDo,
  UIColourContrastDont,
  TextAlternativesDO,
  TextAlternativesDONT,
  TextAlternativesDO2,
  TextAlternativesDONT2,
  TextAlternativesDO4,
  TextAlternativesDONT4,
  Accordion,
  AccordionItem,
  CodeSnippet,
  React
};