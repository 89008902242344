import React from 'react'
import { Toggletip } from '@sainsburys-tech/fable'

export const ToggleTipLabelExample = () => {
  return (
    <div className="ds-flex ds-justify-center" style={{ minHeight: '150px' }}>
      <Toggletip label="Label" />
    </div>
  )
}

export const ToggleTipParagraphExample = () => {
  return (
    <div className="ds-flex ds-justify-center" style={{ minHeight: '150px' }}>
      <Toggletip message="This is toggletip paragraph content. Keep toggletip content short." />
    </div>
  )
}

export const DoToggleTipExample1 = () => {
  return (
    <Toggletip
      label="Label"
      message="Your account reference could be called your account number, customer
  reference or customer number."
    />
  )
}

export const DontToggleTipExample1 = () => {
  return (
    <Toggletip
      label="Label"
      message={
        <>
          <p>What’s my customer reference</p>
          <p>
            Depending on what type of account you have with us, your account
            reference could be called one of the following:
          </p>
          <p>Account number, customer number, customer reference or similar.</p>
          <p>
            You’ll be able to find it on a letter that we’ve sent you, or in
            your welcome pack, or in the emails we sent when you signed up.
          </p>
        </>
      }
    />
  )
}
