import React from 'react'
import { Menu, MenuItem, ExpandableMenu } from '@sainsburys-tech/fable'
import { Home, LandlinePhone, Account } from '@sainsburys-tech/icons'

export const SingleLevelMenuExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center ">
      <div>
        <Menu
          style={{
            minWidth: '150px',
            backgroundColor: 'white',
          }}
        >
          <MenuItem href="#" icon={<Home />}>
            Home
          </MenuItem>
          <MenuItem href="#" isActive icon={<Account />}>
            Account
          </MenuItem>
          <MenuItem href="#" icon={<LandlinePhone />}>
            Contact Us
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export const TwoLevelMenuExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center ">
      <div>
        <Menu
          style={{
            minWidth: '250px',
            backgroundColor: 'white',
          }}
        >
          <ExpandableMenu label="Shop by product">
            <MenuItem href="#">Bed</MenuItem>
            <MenuItem href="#">Sofa</MenuItem>
          </ExpandableMenu>
          <ExpandableMenu label="Shop by room">
            <MenuItem href="#" isActive>
              Living room
            </MenuItem>
            <MenuItem href="#">Bedroom</MenuItem>
            <MenuItem href="#">Dining room</MenuItem>
            <MenuItem href="#">Kitchen</MenuItem>
          </ExpandableMenu>
          <MenuItem href="#">Sale</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export const ThreeLevelMenuExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center ">
      <div>
        <Menu
          style={{
            minWidth: '250px',
            backgroundColor: 'white',
          }}
        >
          <MenuItem href="#">Meat & Fish</MenuItem>
          <MenuItem href="#">Dairy, Eggs & Chilled</MenuItem>
          <ExpandableMenu label="Fruit & Veg">
            <MenuItem href="#">Fresh fruit</MenuItem>
            <MenuItem href="#">Fresh veg</MenuItem>
            <ExpandableMenu label="Fresh Salad">
              <MenuItem href="#">Salad bags</MenuItem>
              <MenuItem href="#">Salad bowls</MenuItem>
              <MenuItem href="#" isActive>
                All salads
              </MenuItem>
            </ExpandableMenu>
          </ExpandableMenu>
          <MenuItem href="#">Bakery</MenuItem>
          <MenuItem href="#">Food Cupboard</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export const DividerMenuExample = () => {
  return (
    <div className="ds-flex ds-items-center ds-justify-center ">
      <div>
        <Menu
          style={{
            minWidth: '150px',
            backgroundColor: 'white',
          }}
        >
          <MenuItem href="#">Shop All</MenuItem>
          <MenuItem hasDivider href="#">
            Sale
          </MenuItem>
          <MenuItem href="#">My Account</MenuItem>
          <MenuItem href="#">Settings</MenuItem>
          <MenuItem href="#" isActive>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
