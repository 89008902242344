import React, { useState } from 'react'
import { Modal, Button } from '@sainsburys-tech/fable'

import { Basket } from '@sainsburys-tech/icons'

const ModalExample = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div>
        <Button onClick={() => setIsOpen(true)}>Open</Button>
        <Modal
          isOpen={isOpen}
          onClose={setIsOpen}
          layout="reverse"
          header={{
            label: 'Modal Header',
            supportingText: 'This is the supporting text',
            isDismissible: true,
            icon: <Basket />,
          }}
          footerButtons={{
            tertiary: {
              label: 'Cancel',
              onClick: () => {
                setIsOpen(false)
              },
            },
            secondary: {
              label: 'More Info',
              onClick: () => {},
            },
            primary: {
              label: 'Submit',
              onClick: () => {
                setIsOpen(false)
              },
            },
          }}
        >
          <div>...</div>
        </Modal>
      </div>
    </>
  )
}

export default ModalExample
