import React from 'react'
import { Link } from '@sainsburys-tech/fable'

const InlineLinkExample = () => {
  return (
    <div>
      <span
        style={{
          fontFamily: 'var(--ds-font-family-body-1)',
          fontSize: 'var(--ds-font-size-body-1)',
          fontWeight: 'var(--ds-font-weight-body-1)',
          lineHeight: 'var(--ds-font-line-height-body-1)',
        }}
      >
        Lorem ipsum dolor sit amet <Link href="#">inline link</Link> sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </span>
    </div>
  )
}

export const StandaloneLinkExample = () => {
  return (
    <div>
      <Link href="#" isStandalone>
        Standalone link
      </Link>
    </div>
  )
}

export const BrandedLinkExample = () => {
  return (
    <div>
      <Link href="#" isStandalone>
        Branded Text
      </Link>
    </div>
  )
}

export default InlineLinkExample
