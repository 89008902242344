import React from 'react'
import { ToggleButtonGroup, ToggleButton } from '@sainsburys-tech/fable'
import { Like, Dislike } from '@sainsburys-tech/icons'

export const ToggleButtonExample = () => {
  return (
    <ToggleButtonGroup
      label="Choose direction controls"
      onChange={function noRefCheck() {}}
      value="first"
    >
      <ToggleButton value="first">First</ToggleButton>
      <ToggleButton value="second">Second</ToggleButton>
      <ToggleButton value="third">Third</ToggleButton>
    </ToggleButtonGroup>
  )
}

export const IconToggleButtonGroup = () => {
  return (
    <ToggleButtonGroup
      label="Choose direction controls"
      onChange={function noRefCheck() {}}
      value="first"
    >
      <ToggleButton
        icon={<Like />}
        kind="icon"
        label="Like"
        onClick={function noRefCheck() {}}
        value="Like test value"
      >
        Like
      </ToggleButton>
      <ToggleButton
        icon={<Dislike />}
        kind="icon"
        label="DisLike"
        onClick={function noRefCheck() {}}
        value="Dislikeike test value"
      >
        Dislike
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export const MixedToggleButtonGroup = () => {
  return (
    <ToggleButtonGroup
      label="Choose direction controls"
      onChange={function noRefCheck() {}}
      value="first"
    >
      <ToggleButton
        icon={<Like />}
        kind="icon"
        label="Like"
        onClick={function noRefCheck() {}}
        value="Like test value"
      >
        Like
      </ToggleButton>
      <ToggleButton
        icon={<Dislike />}
        kind="icon"
        label="DisLike"
        onClick={function noRefCheck() {}}
        value="Dislikeike test value"
      >
        Dislike
      </ToggleButton>
      <ToggleButton value="Skip">Skip</ToggleButton>
    </ToggleButtonGroup>
  )
}
