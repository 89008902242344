import React from 'react'

import { Button, GridWrapper, Accordion, AccordionItem } from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const SentenceCase = () => (
  <section>
    <h2>Use sentence case text</h2>

    <p>
      Only use uppercase text for abbreviating a previously defined word or
      phrase.
    </p>

    <Accordion standalone>
      <AccordionItem title="Why avoid uppercase sentences and words?">
        <p>Sentence case sentences and words are easier to read because:</p>
        <ul>
          <li>
            users with cognitive and visual impairments rely heavily on the
            shape of words to identify them
          </li>
          <li>
            the shape of sentence case words is distinct and familiar, unlike
            uniformly shaped uppercase text which takes longer to read and
            process
          </li>
        </ul>
      </AccordionItem>
    </Accordion>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        code={null}
        component={
          <>
            <Button variant="filled" color="dark">
              Buy now
            </Button>
          </>
        }
      />
      <CodeSnippet
        type="negative"
        code={null}
        component={
          <>
            <Button variant="filled" color="dark">
              BUY NOW
            </Button>
          </>
        }
      />
    </GridWrapper>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        code={null}
        component={
          <p>
            This sentence is written in sentence case. The words have a distinct
            shape and are easily recognisable.
          </p>
        }
      />
      <CodeSnippet
        type="negative"
        code={null}
        component={
          <p>
            THIS SENTENCE IS WRITTEN IN UPPERCASE. THE WORDS HAVE A UNIFORMED
            RECTANGULAR SHAPE WHICH MAKES THEM HARDER TO DISTINGUISH.
          </p>
        }
      />
    </GridWrapper>
  </section>
)

export default SentenceCase
