import React from 'react'
import { Display3 } from '@sainsburys-tech/fable'

const TableOfContents = ({ title, children }) => (
  <div className="c-toc">
    <div className="c-toc--container">
      {title && <Display3 as="h2">{title}</Display3>}
      {children}
    </div>
  </div>
)

export default TableOfContents
