import React from 'react'
import { Display5, Body1 } from '@jsluna/typography'
import { TableContainer, TableBody } from '@jsluna/table'

import ColourBreadcrumbs from './ColourBreadcrumbs'
import Breadcrumbs from './Breadcrumbs'
import TokenTableRows from './TokenTableRows'
import ColourTableHeader from './ColourTableHeader'
import TableHeader from './TableHeader'

import TypographyToken from './TypographyToken'

import tokensData from './tokens'

const TokensLookup = ({
  brand,
  platform,
  removeBottomBorder,
  tagline,
  title,
  token,
  tokenItem,
  tokens,
  type,
}) => {
  const dsTokens = tokensData.find(datum => datum.type === type)[type]
  const dsBrandTokens = dsTokens.find(brandNode => brandNode.name === brand)

  if (type === 'font' && token && tokenItem) {
    return (
      <TypographyToken
        token={token}
        tokenItem={tokenItem}
        typographyTokens={dsBrandTokens}
        platform={platform}
      />
    )
  }

  const tokenTitle = type === 'color' && title === 'Brand' ? 'Palette' : title

  console.log('Token title ', tokenTitle)
  const selectTokens = tokenTitle
    ? dsBrandTokens[tokenTitle.toLowerCase().replace(' ', '-')]
    : dsBrandTokens

  const dataKeys = Object.keys(selectTokens)

  let tokenKeys = tokens

  if (type === 'color' && title === 'Brand') {
    tokenKeys = dataKeys.filter(key => key.includes('brand'))
  } else if (type === 'time') {
    tokenKeys = dataKeys.filter(key => !key.includes('name'))
  } else {
    tokenKeys = dataKeys.filter(key => !key.includes('brand'))
  }


  return (
    <div
      className={`c-tokens-lookup ${
        !removeBottomBorder ? 'c-tokens-lookup--border-bottom' : ''
      }`}
    >
      <Display5 element="h2" className="c-table--title">
        {title}
      </Display5>
      {type === 'color' ? (
        <ColourBreadcrumbs title={title} brand={brand} />
      ) : (
        <Breadcrumbs title={title} type={type} />
      )}
      <Body1 element="p">{tagline}</Body1>
      <TableContainer className="c-table--container">
        {type === 'color' ? <ColourTableHeader /> : <TableHeader />}
        <TableBody>
          <TokenTableRows
            title={tokenTitle}
            tokens={tokenKeys}
            selectTokens={selectTokens}
            type={type}
          />
        </TableBody>
      </TableContainer>
    </div>
  )
}

export default TokensLookup
