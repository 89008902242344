import React from 'react'
import { Counter } from '@sainsburys-tech/fable'

export const CounterHorizontalExample = () => {
  return (
    <Counter
      decrementButtonLabel="Decrement value"
      editValueButtonLabel="Edit value"
      incrementButtonLabel="Increment value"
      label="Label"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
    />
  )
}

export const CounterVerticalExample = () => {
  return (
    <Counter
      decrementButtonLabel="Decrement value"
      editValueButtonLabel="Edit value"
      incrementButtonLabel="Increment value"
      label="Label"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      orientation="vertical"
    />
  )
}

export const CounterBinIconExample = () => {
  return (
    <Counter
      decrementButtonLabel="Decrement value"
      editValueButtonLabel="Edit value"
      incrementButtonLabel="Increment value"
      label="Label"
      onBlur={function noRefCheck() {}}
      onChange={function noRefCheck() {}}
      onFocus={function noRefCheck() {}}
      onKeyDown={function noRefCheck() {}}
      hasDelete
    />
  )
}
