import React from 'react'
import { Pagination } from '@sainsburys-tech/fable'

const PaginationExample = () => {
  return (
    <div>
      <Pagination
        currentPageNumber={3}
        onChange={function noRefCheck() {}}
        totalPageCount={10}
      />
    </div>
  )
}

export const CompactPaginationExample = () => {
  return (
    <div className="ds-mx-auto" style={{ width: '350px' }}>
      <Pagination
        isCompact
        currentPageNumber={3}
        onChange={function noRefCheck() {}}
        totalPageCount={10}
      />
    </div>
  )
}

export default PaginationExample
