import React from 'react'
import { Box } from '@sainsburys-tech/fable'
import ThemeContext from '../../context/ThemeContext'
import './styles.css'

const ExampleBox = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Box
          className="c-component-example-box"
          as="div"
          hasBorder
          hasBorderRadius
        >
          <span className="c-component-example-box--title">
            Current active theme is <strong>{theme.punctuatedBrand}</strong>.
            You can see all developer documentation on our{' '}
            <strong>
              <a
                target="_blank"
                href="https://sainsburys-tech.github.io/design-systems/"
              >
                Fable Storybook site
              </a>
            </strong>
            .
          </span>
          <div className={`ds-theme--${theme.brand} ds-p-4`}>{children}</div>
        </Box>
      )}
    </ThemeContext.Consumer>
  )
}

export default ExampleBox
