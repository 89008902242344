import React, { Children } from 'react'
import * as LnTable from '@jsluna/table'
import TableRow from './TableRow'

const TableBody = ({ children }) => {
  return (
    <LnTable.TableBody>
      {Children.map(children, child => {
        return (
          <>
            {child.props.mdxType === 'TableRow' ? (
              <TableRow mdxType={child.props.mdxType} {...child.props}>
                {child.props.children}
              </TableRow>
            ) : (
              Children.only(child, grandchild => {
                return (
                  <TableRow
                    mdxType={grandchild.props.mdxType}
                    {...grandchild.props}
                  >
                    {grandchild.props.children}
                  </TableRow>
                )
              })
            )}
          </>
        )
      })}
    </LnTable.TableBody>
  )
}

export default TableBody
