import React from 'react'

import { Section } from '@jsluna/section'

import BrandLockUpHorizontal from '@images/svg/BrandLockUp-Horizontal.svg'
import BrandLockUpVertical from '@images/svg/BrandLockUp-Vertical.svg'
import { Display1 } from '@jsluna/typography'

const LogoLockup = () => {

  return (
    <Section className="c-logo-lockup">
      <Display1 element='h2' className="ln-u-text-align-center ln-u-display-block ln-u-soft-ends">
        Our brands
      </Display1>
      <div className="ln-u-soft-bottom-lg c-logo-lockup--desktop">
        <BrandLockUpHorizontal />
      </div>
      <div className="ln-u-soft-bottom-lg c-logo-lockup--mobile">
        <BrandLockUpVertical />
      </div>
    </Section>
  )
}

export default LogoLockup
