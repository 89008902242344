import React from 'react'
import { Checkbox } from '@sainsburys-tech/fable'

const CheckboxExample = () => {
  return (
    <div>
      <Checkbox
        label="Get groceries"
        name="checked1"
        onChange={function noRefCheck() {}}
      />
      <Checkbox
        label="Get clothes"
        name="checked2"
        onChange={function noRefCheck() {}}
      />
      <Checkbox
        label="Get homegoods"
        name="checked3"
        onChange={function noRefCheck() {}}
      />
    </div>
  )
}
export default CheckboxExample
