import React from 'react'

import {
  GridWrapper,
  Button,
  Accordion,
  AccordionItem,
  Link,
} from '@jsluna/react'

import CodeSnippet from './CodeSnippet'

const SemanticMarkup = () => (
  <section>
    <h2>Use semantic markup</h2>

    <p>
      Use semantic markup to give browsers and assistive technologies like
      screen readers information about UI controls and structure.
    </p>

    <Accordion standalone>
      <AccordionItem title="Why is semantic markup important?">
        <p>Semantic markup is important because:</p>

        <ul>
          <li>
            semantic elements clearly communicate what they&apos;re for and how
            they can be used by the browser and users of assistive technology
            like screen readers
          </li>
          <li>
            semantic elements like links, buttons, and input fields inherit
            features like keyboard accessibility and focus states from the
            browser
          </li>
          <li>
            structural semantic elements like headings and lists can be used by
            screen reader users to navigate
          </li>
          <li>
            non-semantic elements like `div` and `span` don’t communicate any
            meaning (you need additional markup to make them semantic and
            accessible)
          </li>
        </ul>
      </AccordionItem>
    </Accordion>

    <h3>Semantic UI controls</h3>

    <p>
      Semantic elements will tell browsers and assistive technologies what a
      control is and how it should work. When implementing a button, for
      example, use the <code>&lt;button&gt;</code> element and the{' '}
      <Link href="https://www.w3schools.com/tags/att_button_type.asp">
        relevant <code>type</code>
      </Link>{' '}
      attribute.
    </p>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        component={
          <Button variant="outlined" fullWidth>
            This is a button
          </Button>
        }
      />
      <CodeSnippet
        type="negative"
        component={
          <div className="ln-c-button ln-c-button--outlined ln-c-button--full">
            This just looks like a button
          </div>
        }
      />
    </GridWrapper>

    <h3>Semantic document structure</h3>

    <p>
      Semantic markup can also communicate structure, hierarchy and context
      programmatically. When implementing a heading, for example, use the{' '}
      <code>h1</code> to <code>h6</code> tags instead of styling a non-semantic
      element to look like a heading.
    </p>

    <GridWrapper equalHeight>
      <CodeSnippet
        type="positive"
        component={<h2>This is a real heading</h2>}
      />
      <CodeSnippet
        type="negative"
        component={
          <span className="ln-u-h2 ln-u-margin-bottom*2">
            This just looks like a heading
          </span>
        }
      />
    </GridWrapper>
  </section>
)

export default SemanticMarkup
