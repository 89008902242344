import React from 'react'
import GlobalTokens from '@sainsburys-tech/design-tokens/dist/global/json/tokens.json'
import SainsburysTokens from '@sainsburys-tech/tokens/dist/sainsburys/js/json/color/index.json'
import ArgosTokens from '@sainsburys-tech/tokens/dist/argos/js/json/color/index.json'
import NectarTokens from '@sainsburys-tech/tokens/dist/nectar/js/json/color/index.json'
import HabitatTokens from '@sainsburys-tech/tokens/dist/habitat/js/json/color/index.json'
import TuTokens from '@sainsburys-tech/tokens/dist/tu/js/json/color/index.json'

import styled from 'styled-components'

import ColourBlock from '../colour-block'

import { TableCell } from '@jsluna/table'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Section } from '@jsluna/section'

const InfoBlock = styled.div`
  & > div {
    font-weight: bold;
    display: block;
  }
`

export const EasySelectText = styled.span`
  user-select: all;
`

export const TableCellResult = ({ children, success }) => (
  <TableCell
    style={{
      color: `var(--ds-color-semantic-${success ? 'success' : 'error'}-base)`,
    }}
  >
    {children}
  </TableCell>
)
//   text-align: ${({ alignLeft }: { alignLeft: boolean }) =>
//     alignLeft ? 'left' : 'center'};
//   color: ${({ success }: { success: string }) =>
//     success
//       ? GlobalTokens.color.semantic.success.base
//       : GlobalTokens.color.semantic.error.base};
// `

const capitiseStr = str =>
  str.split(' ').map(x => x.replace(x[0], x[0].toUpperCase()))

const brands = {
  sainsburys: SainsburysTokens,
  argos: ArgosTokens,
  habitat: HabitatTokens,
  tu: TuTokens,
  nectar: NectarTokens,
  global: GlobalTokens,
}

export const PrimaryColours = ({
  brand = 'global',
  children,
  showBrandName = false,
}) => {
  if (
    brands[brand].color.palette['brand-1'] &&
    brands[brand].color.palette['brand-2']
  ) {
    return (
      <Section>
        <GridWrapper equalHeight={true} matrix={true} gutterSize="sm">
          <GridItem size="1/2">
            <ColourBlock
              fill={brands[brand].color.palette['brand-1']}
              color="white"
            >
              <InfoBlock>
                {showBrandName && <div>{capitiseStr(brand)}</div>}
                <div>Primary 1</div>
                {children ? <div>{children}</div> : <div>Base</div>}
              </InfoBlock>
              <EasySelectText>
                {brands[brand].color.palette['brand-1']}
              </EasySelectText>
            </ColourBlock>
          </GridItem>
          <GridItem size="1/2">
            <ColourBlock fill={brands[brand].color.palette['brand-2']}>
              <InfoBlock>
                {showBrandName && <div>{capitiseStr(brand)}</div>}
                <div>Primary 2</div>
                {children ? <div>{children}</div> : <div>Base</div>}
              </InfoBlock>
              <EasySelectText>
                {brands[brand].color.palette['brand-2']}
              </EasySelectText>
            </ColourBlock>
          </GridItem>
        </GridWrapper>
      </Section>
    )
  } else {
    return <span>Nothing found for {brand}</span>
  }
}

export const SecondaryColours = ({
  brand = 'global',
  children,
  showBrandName = false,
}) => {
  if (brands[brand].color[`campaign-1`]) {
    const colourNames = [
      'leaf',
      'kiss',
      'water',
      'earth',
      'lavender',
      'sky',
      'cloud',
    ]
    const secondaryColours = colourNames.map(
      (x, i) => brands[brand].color[`campaign-${i + 1}`].base,
    )
    return (
      <Section>
        <GridWrapper equalHeight={true} matrix={true} gutterSize="sm">
          {secondaryColours.map((colourHexCode, i) => (
            <GridItem size="1/4" key={i}>
              <ColourBlock fill={colourHexCode}>
                <InfoBlock>
                  {showBrandName && <div>{capitiseStr(brand)}</div>}
                  <div>{capitiseStr(colourNames[i])}</div>
                  {children ? <div>{children}</div> : <div>Base</div>}
                </InfoBlock>
                <EasySelectText>{colourHexCode}</EasySelectText>
              </ColourBlock>
            </GridItem>
          ))}
        </GridWrapper>
      </Section>
    )
  } else {
    return <span>Nothing found for {brand}</span>
  }
}

export const PromotionalColours = ({
  brand = 'global',
  children,
  showBrandName = false,
}) => {
  const promoColours = brands[brand].color['promotion']
  if (promoColours) {
    const promoColoursKeys = Object.keys(promoColours)
    return (
      <Section>
        <GridWrapper equalHeight={true} matrix={true} gutterSize="sm">
          {promoColoursKeys.map(item => (
            <GridItem key={item} size="1/4">
              <ColourBlock fill={promoColours[item].base}>
                <InfoBlock>
                  {showBrandName && <div>{capitiseStr(brand)}</div>}
                  <div>{capitiseStr(item)}</div>
                  {children ? <div>{children}</div> : <div>Base</div>}
                </InfoBlock>
                <EasySelectText>{promoColours[item].base}</EasySelectText>
              </ColourBlock>
            </GridItem>
          ))}
        </GridWrapper>
      </Section>
    )
  } else {
    return <span>No promotional colours found for {brand}</span>
  }
}

// BaseColours Component - A lookup for the base of an extended colour palette type
export const BaseColours = ({
  brand = 'global',
  children,
  showBrandName = false,
  colourLookup,
}) => {
  colourLookup = colourLookup ? colourLookup : 'brand-1'
  if (brands[brand].color[colourLookup]) {
    return (
      <Section>
        <ColourBlock fill={brands[brand].color[colourLookup].base}>
          <InfoBlock>
            {showBrandName && <div>{capitiseStr(brand)}</div>}
            <div>{capitiseStr(colourLookup)}</div>
            {children ? <div>{children}</div> : <div>Base</div>}
          </InfoBlock>
          <EasySelectText>
            {brands[brand].color[colourLookup].base}
          </EasySelectText>
        </ColourBlock>
      </Section>
    )
  } else {
    return (
      <span>
        Nothing found for {colourLookup} in {brand}
      </span>
    )
  }
}

export const ExtendedColours = ({
  brand = 'global',
  children,
  showBrandName = false,
  colourType = 'brand-1',
  showColourType,
}) => {
  const extColours = brands[brand].color[colourType]
  if (typeof extColours === 'object') {
    const extColoursKeys = Object.keys(extColours)
      .filter(x => x !== 'base')
      .reverse()
    return (
      <Section>
        <GridWrapper equalHeight={true} matrix={true} gutterSize="sm">
          {extColoursKeys.map(item => (
            <GridItem key={item} size="1/4">
              <ColourBlock fill={extColours[item]}>
                <InfoBlock>
                  {showBrandName && <div>{capitiseStr(brand)}</div>}
                  {showColourType && <div>{capitiseStr(colourType)}</div>}
                  {children && <div>{children}</div>}
                  <div>{capitiseStr(item)}</div>
                </InfoBlock>
                <EasySelectText>{extColours[item]}</EasySelectText>
              </ColourBlock>
            </GridItem>
          ))}
        </GridWrapper>
      </Section>
    )
  } else {
    return (
      <span>
        Nothing found for {colourType} in {brand}{' '}
        <code>{JSON.stringify(brands[brand], null, 2)}</code>{' '}
      </span>
    )
  }
}

export const MonochromeColours = ({
  brand = 'global',
  children,
  showBrandName = false,
}) => {
  const monochromeColours = brands[brand].color['monochrome']
  const monochromeColoursKeys = Object.keys(monochromeColours)
    .filter(
      x =>
        ![
          'grey-v-light',
          'grey-light',
          'grey',
          'grey-dark',
          'grey-darker',
        ].find(y => y === x),
    )
    .reverse()
  return (
    <Section>
      <GridWrapper equalHeight={true} matrix={true} gutterSize="sm">
        {monochromeColoursKeys.map(item => (
          <GridItem size="1/4" key={item}>
            <ColourBlock fill={monochromeColours[item]}>
              <InfoBlock>
                {!showBrandName && <div>{capitiseStr(brand)}</div>}
                {children ? <div>{children}</div> : <div>Monochrome</div>}
                <div>{capitiseStr(item)}</div>
              </InfoBlock>
              <EasySelectText>{monochromeColours[item]}</EasySelectText>
            </ColourBlock>
          </GridItem>
        ))}
      </GridWrapper>
    </Section>
  )
}

export const SemanticColours = ({
  brand = 'global',
  children,
  showBrandName = false,
  extendedTarget,
}) => {
  brand = Boolean(brand) ? brand : 'global'
  const semanticColours = brands[brand].color.semantic
  // filter base and text colour
  const semanticColoursKeys = Object.keys(semanticColours)
  return (
    <Section>
      <GridWrapper equalHeight={true} matrix={true} gutterSize="sm">
        {Boolean(extendedTarget)
          ? Object.keys(semanticColours[extendedTarget]).map(extColour => (
              <GridItem key={extColour} size="1/4">
                <ColourBlock fill={semanticColours[extendedTarget][extColour]}>
                  <InfoBlock>
                    <div>{capitiseStr(extendedTarget)}</div>
                    {children && <div>{children}</div>}
                    <div>{capitiseStr(extColour)}</div>
                  </InfoBlock>
                  <EasySelectText>
                    {semanticColours[extendedTarget][extColour]}
                  </EasySelectText>
                </ColourBlock>
              </GridItem>
            ))
          : semanticColoursKeys.map(item => {
              return (
                <GridItem key={item} size="1/4">
                  <ColourBlock fill={semanticColours[item].base}>
                    <InfoBlock>
                      <div>
                        {showBrandName && <div>{capitiseStr(brand)}</div>}
                      </div>
                      {children && <div>{children}</div>}
                      <div>{capitiseStr(item)}</div>
                    </InfoBlock>
                    <EasySelectText>
                      {semanticColours[item].base}
                    </EasySelectText>
                  </ColourBlock>
                </GridItem>
              )
            })}
      </GridWrapper>
    </Section>
  )
}

export const ColourLookup = ({
  brand = 'habitat',
  children,
  lookup = ['color', 'campaign-1', 'base'],
  customColour,
}) => {
  const colourPalette = customColour
    ? customColour
    : brands[brand]?.[lookup[0]]?.[lookup[1]]?.[lookup[2]]
  return (
    <ColourBlock fill={colourPalette}>
      {children && (
        <InfoBlock>
          <div>{children}</div>
        </InfoBlock>
      )}
      <EasySelectText>{colourPalette}</EasySelectText>
    </ColourBlock>
  )
}
