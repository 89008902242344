import React from 'react'
import { TableRow, TableCell } from '@jsluna/table'

import { formatTableText, responsiveScreenSizes } from '@utils/formatTokenTable'

import TokensLookup from '@components/tokens-lookup'


const typography = [ 'display-6', 'display-5', 'display-4', 'display-3', 'display-2', 'display-1', 'label-2', 'label-1', 'body-2', 'body-1', 'caption']

export const NectarTypographyTableBody = ({ platform }) => {
  return (
    <>
      {typography.map(type => {
        const responsiveSize = type.includes('display')
          ? responsiveScreenSizes(type)
          : null
        return (
          <TableRow>
            <TableCell>{formatTableText(type)}</TableCell>
            <TableCell>
              <span className={`ds-theme--nectar ds-u-${type}`}>
                <TokensLookup
                  type="font"
                  brand="Nectar"
                  token={type}
                  tokenItem="font-family"
                  display={type}
                  platform={platform}
                />
              </span>
            </TableCell>
            <TableCell>
              <TokensLookup
                type="font"
                brand="Nectar"
                token={type}
                tokenItem="font-size"
                platform={platform}
              />
            </TableCell>
            <TableCell>
              <TokensLookup
                type="font"
                brand="Nectar"
                token={type}
                tokenItem="line-height"
              />
            </TableCell>
            <TableCell>
              {responsiveSize && (
                <span style={{ textTransform: 'capitalize' }}>
                  {responsiveSize.replace('-', ' ')}
                </span>
              )}
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default NectarTypographyTableBody
