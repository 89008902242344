import React from 'react'
import { Combobox } from '@sainsburys-tech/fable'

export const ComboBoxSingleSelect = () => {
  return (
    <div style={{ minHeight: '320px' }}>
      <Combobox
        label="Label"
        onChange={function noRefCheck() {}}
        onClear={function noRefCheck() {}}
        onSelection={function noRefCheck() {}}
        supportingText="Supporting text"
        options={[
          { value: 'option-1', label: 'Option 1' },
          { value: 'option-2', label: 'Option 2' },
          { value: 'option-3', label: 'Option 3' },
          { value: 'option-4', label: 'Option 4' },
        ]}
      />
    </div>
  )
}

export const ComboBoxMultiSelect = () => {
  return (
    <div style={{ minHeight: '320px' }}>
      <Combobox
        label="Label"
        onChange={function noRefCheck() {}}
        onClear={function noRefCheck() {}}
        onSelection={function noRefCheck() {}}
        isMultiSelect
        supportingText="Supporting text"
        options={[
          { value: 'option-1', label: 'Option 1' },
          { value: 'option-2', label: 'Option 2' },
          { value: 'option-3', label: 'Option 3' },
          { value: 'option-4', label: 'Option 4' },
        ]}
      />
    </div>
  )
}

export const ComboBoxMultiSelectButton = () => {
  return (
    <div style={{ minHeight: '320px' }}>
      <Combobox
        button={{ text: 'Button', onClick: () => alert('Button clicked') }}
        label="Label"
        isMultiSelect={true}
        supportingText="Supporting text"
        options={[
          { value: 'option-1', label: 'Option 1' },
          { value: 'option-2', label: 'Option 2' },
          { value: 'option-3', label: 'Option 3' },
          { value: 'option-4', label: 'Option 4' },
        ]}
      />
    </div>
  )
}

export const ComboBoxMultiSelectWithSelections = () => {
  return (
    <div style={{ minHeight: '320px' }}>
      <Combobox
        label="Label"
        onChange={function noRefCheck() {}}
        onClear={function noRefCheck() {}}
        onSelection={function noRefCheck() {}}
        isMultiSelect
        supportingText="Supporting text"
        options={[
          { value: 'option-1', label: 'Option 1' },
          { value: 'option-2', label: 'Option 2' },
          { value: 'option-3', label: 'Option 3' },
          { value: 'option-4', label: 'Option 4' },
        ]}
        initialSelectedItems={[
          { value: 'option-3', label: 'Option 3' },
          { value: 'option-4', label: 'Option 4' },
        ]}
      />
    </div>
  )
}
