import React from 'react'
import classNames from 'classnames'

import { Card as LnCard } from '@jsluna/card'
import Icon from '../icon/index'

const Card = ({
  children,
  className,
  border,
  filled,
  hasCodeSnippet,
  icon,
  ...rest
}) => {
  return (
    <LnCard
    className={
      classNames(
        'c-card',
        className, 
        {'c-card--no-border': border === false}, 
        {'c-card--filled': filled}, 
        {'ln-u-hard-bottom ln-u-hard-sides': hasCodeSnippet}
        )
      }

      {...rest}
    >
      {icon && <Icon iconName={icon} />}
      {children}
    </LnCard>
  )
}

export default Card
