import React from 'react'
import {
  TableHeader as LnTableHeader,
  TableHeaderRow,
  TableHeaderCell,
} from '@jsluna/table'

const TableHeader = () => {
  return (
    <LnTableHeader>
      <TableHeaderRow>
        <TableHeaderCell>Token</TableHeaderCell>
        <TableHeaderCell>Value</TableHeaderCell>
      </TableHeaderRow>
    </LnTableHeader>
  )
}

export default TableHeader
