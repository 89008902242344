import React from 'react'
import { RadioGroup, RadioButton } from '@sainsburys-tech/fable'

const RadioGroupExample = () => {
  return (
    <div>
      <RadioGroup
        defaultValue="first"
        label="Radio group label"
        name="example1"
        options={[
          {
            label: 'First option',
            value: 'first',
          },
          {
            label: 'Second option',
            value: 'second',
          },
          {
            label: 'Third option',
            value: 'third',
          },
        ]}
        supportingText="Here is some supporting text"
      />
    </div>
  )
}

export const InlineRadioGroupExample = () => {
  return (
    <div>
      <RadioGroup
        isInline
        defaultValue="first"
        label="Radio group label"
        name="example2"
        options={[
          {
            label: 'First option',
            value: 'first',
          },
          {
            label: 'Second option',
            value: 'second',
          },
          {
            label: 'Third option',
            value: 'third',
          },
        ]}
        supportingText="lorem ipsum dolor sit amet consectetur adipiscing elit"
      />
    </div>
  )
}

export const OutlinedRadioGroupExample = () => {
  return (
    <div>
      <RadioGroup
        isOutlined
        defaultValue="first"
        label="Radio group label"
        name="example3"
        options={[
          {
            label: 'First option',
            value: 'first',
          },
          {
            label: 'Second option',
            value: 'second',
          },
          {
            label: 'Third option',
            value: 'third',
          },
        ]}
        supportingText="lorem ipsum dolor sit amet consectetur adipiscing elit"
      />
    </div>
  )
}

export const ErroredRadioGroupExample = () => {
  return (
    <div>
      <RadioGroup
        isInline
        defaultValue="first"
        label="Radio group label"
        name="example4"
        options={[
          {
            label: 'First option',
            value: 'first',
          },
          {
            label: 'Second option',
            value: 'second',
            status: 'warning',
            message: 'You can also be warned',
          },
          {
            label: 'Third option',
            value: 'third',
          },
        ]}
        status={{
          type: 'error',
          message: 'This is an error message',
        }}
        supportingText="lorem ipsum dolor sit amet consectetur adipiscing elit"
      />
    </div>
  )
}

export const StandaloneRadioButton = () => {
  return (
    <RadioButton
      isStandalone
      label="Label"
      onChange={function noRefCheck() {}}
    />
  )
}

export default RadioGroupExample
