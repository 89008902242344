import React, { useEffect, useRef } from 'react'
import { LoadingIndicator, Button } from '@sainsburys-tech/fable'

export const ProgressSpinnerExample = () => {
  return (
    <div style={{ margin: 'auto', 'padding-bottom': '24px' }}>
      <LoadingIndicator size="md" variant="primary" />
    </div>
  )
}

export const ProgressBarExample = () => {
  return <LoadingIndicator size="md" variant="secondary" />
}

export const DeterminateProgressSpinnerExample = () => {
  const [progress, setProgress] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)

  let timer

  const startProgress = () => {
    setIsLoading(true)
    setProgress(0)
    timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 100
        }
        const diff = Math.floor(Math.random() * 10)
        return Math.min(oldProgress + diff, 100)
      })
    }, 1000)
  }

  const resetProgress = () => {
    clearInterval(timer)
    setIsLoading(false)
    setProgress(0)
  }

  React.useEffect(() => {
    return () => {
      clearInterval(timer)
    }
  }, [progress === 100])

  return (
    <div>
      <div className="ds-mb-4">
        <Button onClick={!isLoading ? startProgress : resetProgress}>
          {!isLoading ? 'Start' : 'Reset'}
        </Button>
      </div>
      <LoadingIndicator
        label="Loading, please wait."
        progressCount={progress}
        isLoading={isLoading && progress < 100}
      />
    </div>
  )
}
