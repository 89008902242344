import globalTokens from '@sainsburys-tech/design-tokens/dist/global/json/tokens.json'
import sainsburysTokens from '@sainsburys-tech/design-tokens/dist/sainsburys/json/tokens.json'
import argosTokens from '@sainsburys-tech/design-tokens/dist/argos/json/tokens.json'
import nectarTokens from '@sainsburys-tech/design-tokens/dist/nectar/json/tokens.json'
import habitatTokens from '@sainsburys-tech/design-tokens/dist/habitat/json/tokens.json'
import tuTokens from '@sainsburys-tech/design-tokens/dist/tu/json/tokens.json'

const tokens = [
  { ...globalTokens, brand: 'Global' },
  { ...sainsburysTokens, brand: 'Sainsburys' },
  { ...argosTokens, brand: 'Argos' },
  { ...nectarTokens, brand: 'Nectar' },
  { ...habitatTokens, brand: 'Habitat' },
  { ...tuTokens, brand: 'Tu' },
]

const brands = ['Global', 'Sainsburys', 'Argos', 'Nectar', 'Habitat', 'Tu']

const getTokensByType = tokenType => {
  const brandTokensByType = brands.map(brand => {
    const brandTokens = tokens.find(x => x.brand === brand)

    return {
      ...brandTokens[tokenType],
      name: brand,
    }
  })
  return {
    [tokenType]: brandTokensByType,
    type: tokenType,
  }
}

const data = [
  getTokensByType('color'),
  getTokensByType('font'),
  getTokensByType('modifier'),
  getTokensByType('size'),
  getTokensByType('time'),
]

export default data
