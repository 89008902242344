import React from 'react'
import { Breadcrumbs, BreadcrumbsItem } from '@sainsburys-tech/fable'

export const BreadcrumbsCompactExample = () => {
  return (
    <>
      <Breadcrumbs isCompact>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
      </Breadcrumbs>
    </>
  )
}

const BreadCrumbsExample = () => {
  return (
    <>
      <Breadcrumbs>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
        <BreadcrumbsItem href="#">Link</BreadcrumbsItem>
      </Breadcrumbs>
    </>
  )
}

export default BreadCrumbsExample
